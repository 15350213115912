import { Box, ButtonBase, Grid, Stack, Typography, Backdrop, CircularProgress, IconButton, Checkbox, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, } from '@mui/material';
import { AccessTimeFilled, Add } from '@mui/icons-material';
import React, { useState, useEffect, createRef } from 'react';
import { DefaultInput } from '../../../ui/DefaultInput/index.js';
import { DefaultSelect } from '../../../ui/DefaultSelect/index.js';
import { DefaultToggle } from '../../../ui/DefaultToggle/DefaultToggle.jsx';
import { Tabs } from '../../../ui/Tabs/index.js';
import { toolsTabs } from '../../../modules/Asset/data/toolsTabs.jsx';
import { DefaultDataGrid } from './DefaultDataGrid';
import { ConfirmAlert } from '../../../customControls/confirmAlert.js';
import api from '../../../lib/axios.js';
import { Map } from './Map.jsx';
import moment from 'moment'
import { enqueueSnackbar } from 'notistack';
import { pictureColumns, geoFenceColumns, sharingColumns } from '../data/locationMap.jsx';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { status } from 'nprogress';
import { trackersTabs } from '../../../components/Map/data/tabs.js';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { useNavigate } from 'react-router-dom';

export const LocationsMap = ({ personnelData, sensorData, getData }) => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState(toolsTabs[0].value);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [sharingDeleteConfirmationShouldOpen, setSharingDeleteConfirmationShouldOpen] = useState(false);
    const [documentDeleteConfirmationShouldOpen, setDocumentDeleteConfirmationShouldOpen] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [sharingArrayData, setSharingArrayData] = useState([]);
    const [selectedDataId, setSelectedDataId] = useState("");
    const [selectedSharingDataId, setSelectedSharingDataId] = useState("");
    const [selectedDocumentID, setSelectedDocumentID] = useState("");
    const [addGeoFence, setAddGeoFence] = useState(false);
    const [addPicture, setAddPicture] = useState(false);
    const [showPicture, setShowPicture] = useState(false);
    const [showGeo, setShowGeo] = useState(false);
    const [showMoreDetail, setShowMoreDetail] = useState(false);
    const [addSharing, setAddSharing] = useState(false);
    const [showSharing, setShowSharing] = useState(false);
    const [showDocument, setShowDocument] = useState(false);
    const [drawData, setDrawData] = useState(null);
    const [selectedPoligon, setSelectedPoligon] = useState(null);
    const [pictureArrayData, setPictureArrayData] = useState([]);
    const [device1Activebilling, setDevice1Activebilling] = useState(false);
    const [device2Activebilling, setDevice2Activebilling] = useState(false);
    const [eventTime, setEventTime] = useState("");
    const [eventType, setEventType] = useState(0);
    const fileRef = createRef()
    const formik = useFormik({
        initialValues: {
            submit: null,
            fenceName: '',
            isAlert: false,
            email: ''
        },
        validationSchema: Yup.object().shape({
            fenceName: Yup.string().required('Fence name is required'),
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    submit: null,
                    fenceName: values.fenceName,
                    isAlert: values.isAlert,
                    email: values.email,
                }
                formik.setValues(dataValues)
                if (drawData == null) {
                    enqueueSnackbar("Please draw poligon to add geofece", {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                } else {
                    setIsLoading(true)
                    var data = await save(values.fenceName, values.isAlert, values.email);
                    setIsLoading(false)
                    if (data !== undefined && data.data.success) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        getPoligon()
                    } else {
                        setStatus({ success: false });
                        setSubmitting(false);
                        enqueueSnackbar(data.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                }

                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        },
    })

    useEffect(() => {
        if (personnelData && personnelData._id != null) {
            getPoligon()
            pieChart('chartdiv1')
            pieChart('chartdiv2')
            getImageData()
            calculateDuration()
        }
    }, [personnelData]);

    useEffect(() => {
        if (addGeoFence) {
            setAddGeoFence(true)
            hideMoreDetail()
        }
    }, [addGeoFence, addSharing]);

    const getPoligon = () => {
        setIsLoading(true)
        api.get('/apis/PersonnelGeoFencings/byPersonnel/' + personnelData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayData(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function save(name, isAlert, email) {
        let arrayData = []
        const data = drawData;
        if (data.features.length > 0) {
            if (selectedPoligon == null) {
                data.features.map(res => {
                    var feature = {
                        "email": email,
                        "notification": isAlert ? "1" : "0",
                        "name": name,
                        "region": {
                            "type": "Polygon",
                            "coordinates":
                                res.geometry.coordinates
                        },
                        "mode": activeTab,
                    }
                    arrayData.push(feature)
                })
                if (arrayData.length > 0) {
                    await addData(arrayData)
                }
            } else {
                var updatedData = data.features.find(obj => obj.id == selectedPoligon._id)
                if (updatedData) {
                    await updateData(name, updatedData, selectedPoligon, email, isAlert)
                }
            }
        }
    }

    async function addData(arrayData) {
        var data = {
            personnelID: personnelData._id,
            arrayData: arrayData
        }
        return await api.post('/apis/PersonnelGeoFencings/', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var dataValues = {
                    submit: null,
                    fenceName: "",
                    isAlert: false,
                    email: null
                }
                formik.setValues(dataValues)
                setDrawData(null)
                setAddGeoFence(false)

                enqueueSnackbar('Poligon added', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getPoligon()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }


    async function updateData(name, feature, selectedPoligon, email, isAlert) {
        var data = {
            "name": name,
            "email": email,
            "notification": isAlert ? "1" : "0",
            "region": {
                "type": "Polygon",
                "coordinates":
                    feature.geometry.coordinates
            },
        }
        return await api.put('/apis/PersonnelGeoFencings/' + selectedPoligon._id, data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                // console.log("Put", resData);
                var dataValues = {
                    submit: null,
                    fenceName: "",
                    isAlert: false,
                    email: null
                }
                formik.setValues(dataValues)
                setSelectedPoligon(null)
                setDrawData(null)
                setAddGeoFence(false)

                enqueueSnackbar('Poligon updated', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getPoligon()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    function deleteRecord() {
        setIsLoading(true)
        api.delete('/apis/PersonnelGeoFencings/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Poligon deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setSelectedDataId("")
                getPoligon()
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });

            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteRecord()
        } else {
            setSelectedDataId("")
        }
    }

    const onDeleteClick = (row) => {
        setSelectedDataId(row._id)
        setDeleteConfirmationShouldOpen(true)
    };

    const onEditClick = (row) => {
        setSelectedPoligon(row)
        var value = {
            fenceName: row.name,
            isAlert: row.notification == "1" ? true : false,
            email: row.email
        }
        formik.setValues(value)
        setAddGeoFence(true)
    };

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    const onDrawGeofence = (data) => {
        setDrawData(data)
    };


    const onDocumentDeleteClick = (row) => {
        setSelectedDocumentID(row._id)
        setDocumentDeleteConfirmationShouldOpen(true)
    };


    function deleteDocumnet() {
        setIsLoading(true)
        api.delete('/apis/PersonnelImages/' + selectedDocumentID).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Picture deleted", { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'success' });
                setSelectedDocumentID("")
                getImageData()
            } else {
                enqueueSnackbar(res.data.message, { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateDocumentConfirmAlert = (value) => {
        if (value) {
            deleteDocumnet()
        } else {
            setSelectedDocumentID("")
        }
    }


    function hideGeoFence() {
        setShowGeo(false)
        setShowMoreDetail(false)
        setDrawData(null)
        setAddGeoFence(false)
        setSelectedPoligon(null)
        var value = {
            fenceName: '',
            isAlert: false,
            email: null
        }
        formik.setValues(value)
    }

    function hidePicture() {
        setShowGeo(false)
        setShowPicture(false)
        setAddGeoFence(false)
        var value2 = {
            fenceName: '',
            isAlert: false,
            email: null
        }
        formik.setValues(value2)
    }

    function hideMoreDetail() {
        setShowPicture(false)
        setShowMoreDetail(false)
    }

    const pieChart = (chartID) => {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);

        let chart = root.container.children.push(am5percent.PieChart.new(root,
            {
                innerRadius: 50,
                layout: root.verticalLayout
            }));

        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));

        series.slices.template.set("tooltipText", "");
        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: '17px'
        }));


        if (chartID == "chartdiv1") {
            series.get("colors").set("colors", [
                am5.color(0x1E75FF),
                am5.color(0xE6E7E8),
            ]);
            var temp = sensorData.temperature ? parseFloat(sensorData.temperature) : 0
            var other = 100 - temp
            series.data.setAll([
                { sector: "", size: temp },
                { sector: "", size: other },
            ]);
            label.set("text", !isNaN(sensorData.temperature) ? `${parseFloat(sensorData.temperature).toFixed(1)}°F` : "0%");
        } else {
            series.get("colors").set("colors", [
                am5.color(0x1E75FF),
                am5.color(0xE6E7E8),
            ]);
            var other2 = 100 - (parseInt(sensorData.light))
            series.data.setAll([
                { sector: "", size: parseInt(sensorData.light) },
                { sector: "", size: other2 },
            ]);
            label.set("text", !isNaN(sensorData.light) ? `${parseInt(sensorData.light)}%` : "0%");
        }
        series.appear(1000, 100);
    }

    async function uploadFiles(file) {
        setIsLoading(true)
        if (!file.type.startsWith('image/')) {
            enqueueSnackbar("Upload the file in the correct format", {
                anchorOrigin: { horizontal: 'right', vertical: 'top' },
                variant: 'error'
            });
            setIsLoading(false)
            return;
        }
        const formData = new FormData();
        formData.append("file", file);

        formData.append("userName", localStorage.getItem("fullName"));
        api({
            method: "POST",
            url: '/apis/PersonnelImages/uploadimage/' + personnelData._id,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" }
        })
            .then((res) => {
                if (res.data.success) {
                    enqueueSnackbar("Picture uploaded", { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'success' });
                    getImageData()
                } else {
                    enqueueSnackbar(res.data.message, { anchorOrigin: { horizontal: 'right', vertical: 'top' }, variant: 'error' });
                }
                setIsLoading(false)
            }).catch(function (error) {
                console.log(error);
            });
    }

    async function getImageData() {
        // setIsLoading(true)
        return await api.get('/apis/PersonnelImages/byPersonnel/' + personnelData._id).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setPictureArrayData(resData)
                setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function changeStatus(status, id) {
        setIsLoading(true)
        var data = {
            status: status,
        }
        return await api.put('/apis/PersonnelSensors/changestatus/' + id, data).then((res) => {
            if (res.data.success) {
                // console.log("data", res.data)
                getData()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }
    function calculateDuration() {
    
        const end = new Date();
        let sosTime = 0
        let buttonTime = 0
        let durationMs = 0

        let eventType = 0
        let durationString = "";
        const thresholdMins = 3 * 60 * 1000
        if (sensorData.sosTime) {
            const start = new Date(sensorData.sosTime);
            sosTime = end - start;
            
        }
        console.log("buttonTime",sensorData.buttonTime,end)
        if (sensorData.buttonTime) {
            const start = new Date(sensorData.buttonTime);
            buttonTime = end - start;
            console.log("buttonTime",buttonTime)
        }

        if (sensorData.sosTime && sosTime < thresholdMins) {
            durationMs = sosTime
            eventType = 1
        }else if (sensorData.buttonTime && buttonTime < thresholdMins){
            durationMs = buttonTime
            eventType = 2
        }
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
            durationString += " ago";
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        setEventTime(durationString)
        setEventType(eventType)        
    }
    const handleFileUpload = (e) => {
        e.preventDefault()
        if (fileRef.current) {
            fileRef.current.value = null;
            fileRef.current.click()
        }
    }
    return (
        <>
            <Stack
                flex={1}
                direction={'row'}
                sx={{
                    border: '1px solid #E2E2EA',
                    borderRadius: '8px 8px 8px 8px',
                    bgcolor: '#FFFFFF',
                }}
                mb={1}
                width={'calc(100% + 24px)'}
            >
                <Stack gap={3} p={3} borderRight={'1px solid #E2E2EA'} width={430} flexShrink={0}>
                    <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Location & Sensor Data</Typography>
                    <Stack gap={1}>
                        <Typography fontSize={12} color={'#262B40'} fontFamily={'Roboto'} fontWeight={500}>{sensorData != null && sensorData.address ? sensorData.address : "-"}</Typography>
                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                            Last Updated: {sensorData != null && sensorData.latitude != null && sensorData.latitude != "" && sensorData.latitude != "-" && sensorData.timestamp != null && sensorData.timestamp !== "" ? moment(sensorData.timestamp).format("MM/DD/YY h:mmA") : "-"}
                        </Typography>
                        <Box px={2} py={1} ml={-1} width={'calc(100% + 16px)'} bgcolor={'#F6F8FA'} borderRadius={'12px'}>
                            <Grid container spacing={2.5}>
                                <Grid item xs={4}>
                                    <Stack gap={'5px'} >
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={15}>{sensorData != null && sensorData.state == 1 ? "Online" : "Offline"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Status
                                        </Typography>
                                        <Stack mt={'11px'} >
                                            <Box
                                                sx={{
                                                    height: '100px',
                                                    width: '100px',
                                                }}
                                                id={'chartdiv1'}
                                            />
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400} sx={{ pt: 1.5 }} textAlign={'center'}>
                                                Temp
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack direction={'row'} justifyContent="space-between"  >
                                        <Stack gap={"5px"} alignItems={'center'}>
                                            <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={15}>{personnelData != null && personnelData.duration ? personnelData.duration : "-"}</Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                                Area Duration
                                            </Typography>
                                            <Stack mt={'11px'}>
                                                <Box
                                                    sx={{
                                                        height: '100px',
                                                        width: '100px',
                                                    }}
                                                    id={'chartdiv2'}
                                                />
                                                <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400} sx={{ pt: 1.5 }} textAlign={'center'}>
                                                    Light
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={0.5} alignItems={'center'}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={15}>{sensorData != null && eventTime}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Button
                                        </Typography>
                                        <Stack mt={'11px'}>
                                            <Box
                                                sx={{
                                                    height: '100px',
                                                    width: '100px',
                                                    border: '3px solid #DDDDDE',
                                                    bgcolor: eventType == 1 ? "#FE5F57" : eventType == 2 ? "#63C467" : "#F6F7F7",
                                                    alignContent: 'center',
                                                    borderRadius: "50%"
                                                }}
                                            >
                                                <Typography fontSize={15} color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} textAlign={'center'}>
                                                    {eventType == 1 ? "SOS" : eventType == 2 ? "Pressed" : "None"}
                                                </Typography>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>

                    {/* More Detail */}
                    <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>More Details</Typography>
                        <IconButton
                            sx={{
                                padding: "3",
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#F6F8FA',
                                color: 'black',
                            }}
                            onClick={(e) => {
                                setShowMoreDetail(!showMoreDetail)
                                hidePicture()
                            }}
                        >
                            {showMoreDetail ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                        </IconButton >
                    </Stack>
                    {showMoreDetail &&
                        <Stack width={'100%'}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.firstName ? personnelData.firstName : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            First Name
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.lastName ? personnelData.lastName : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Last Name
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.mobile ? personnelData.mobile : "-"}</Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Mobile
                                        </Typography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}
                                            sx={{ textDecoration: personnelData != null && personnelData.sensorNumber ? 'underline' : "none", cursor: personnelData != null && personnelData.sensorNumber ? 'pointer' : 'none' }}
                                        // onClick={() => {
                                        //     if (personnelData != null && personnelData.sensorNumberID) {
                                        //         navigate("/devices/detail", { state: { id: personnelData.sensorNumberID, assetName: personnelData.assetName } })
                                        //     }
                                        // }}
                                        >
                                            {personnelData != null && personnelData.sensorNumber ? personnelData.sensorNumber : "-"}</Typography>

                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Device 1
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {personnelData != null && personnelData.sensor != null && <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Checkbox
                                            sx={{
                                                height: '16px',
                                                width: '16px',
                                            }}
                                            checked={personnelData.sensor.status == "1"}
                                            onChange={(e) => {
                                                changeStatus(e.target.checked ? "1" : "2", personnelData.sensorNumberID)
                                            }}
                                        />
                                        <Typography fontSize={12} color={'#63C467'} fontFamily={'Roboto'} fontWeight={500} pl={0.7}>
                                            $
                                        </Typography>
                                    </Stack>
                                </Grid>
                                }
                                <Grid item xs={4}>
                                    <Stack gap={0.5}>
                                        <Typography color={'#262B40'} fontFamily={'Roboto'} fontWeight={500} fontSize={12}>{personnelData != null && personnelData.email ? personnelData.email : "-"}</Typography>

                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>
                                            Email
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    }

                    {/* Add Picture */}
                    <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Picture</Typography>
                        {!addPicture && <IconButton
                            sx={{
                                padding: "3",
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#F6F8FA',
                                color: 'black',
                            }}
                            onClick={(e) => {
                                setShowPicture(!showPicture)
                                hideGeoFence()
                            }}
                        >
                            {showPicture ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                        </IconButton >}
                    </Stack>
                    {showPicture && <DefaultDataGrid rows={pictureArrayData} columns={pictureColumns(onDocumentDeleteClick, fileRef, uploadFiles)} disablePagination onRowClick={(e) => {
                        if (e.row.path) {
                            window.open(e.row.path, '_blank');
                        }
                    }} sx={{ mt: -3 }}></DefaultDataGrid>}
                    {/* Add GeoFence */}
                    <Stack direction={'row'} gap={2} justifyContent={'space-between'}>
                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={500}>Geofences</Typography>
                        {!addGeoFence && <IconButton
                            sx={{
                                padding: "3",
                                border: 'none',
                                cursor: 'pointer',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '20px',
                                height: '20px',
                                backgroundColor: '#F6F8FA',
                                color: 'black',
                            }}
                            onClick={(e) => {
                                setShowGeo(!showGeo)
                                hideMoreDetail()
                            }}
                        >
                            {showGeo ? <KeyboardArrowDownIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />}
                        </IconButton >}
                    </Stack>

                    {addGeoFence && <FormikProvider value={formik}> <Stack gap={1} component={'form'}
                        onSubmit={formik.handleSubmit}>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                Fence Name
                            </Typography>
                            <DefaultInput
                                defaultValue={formik.values.fenceName}
                                placeholder={'Enter name...'}
                                name={'fenceName'}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.fenceName && formik.errors.fenceName)}
                                helperText={formik.touched.fenceName && formik.errors.fenceName} />
                        </Stack>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                Select Tool
                            </Typography>
                            <Stack
                                flex={1}
                                sx={{
                                    height: 40,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '6px',
                                }}
                            >
                                <Tabs data={toolsTabs} setActiveTab={handleChangeTab} activeTab={activeTab} inline small />
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                Alerts
                            </Typography>
                            <Stack
                                direction={'row'}
                                gap={1}
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                flex={1}
                                sx={{
                                    height: 40,
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '6px',
                                    pl: 2,
                                    pr: 1,
                                }}
                            >
                                <Typography fontSize={14}>Activate Alerts</Typography>
                                <DefaultToggle name={'isAlert'} />
                            </Stack>
                        </Stack>
                        <Stack direction={'row'} gap={1} alignItems={'center'}>
                            <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                                Send To
                            </Typography>
                            <DefaultInput
                                defaultValue={formik.values.email}
                                placeholder={'Enter email'}
                                name={'email'}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                error={Boolean(formik.touched.email && formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Stack>
                        <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#EFEFEF',
                                    borderRadius: '5px',
                                }}
                                onClick={() => {
                                    setDrawData(null)
                                    setAddGeoFence(false)
                                    setSelectedPoligon(null)
                                    var value = {
                                        fenceName: '',
                                        isAlert: false,
                                        email: null
                                    }
                                    formik.setValues(value)
                                }}
                            >
                                <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                    Cancel
                                </Typography>
                            </ButtonBase>
                            <ButtonBase
                                sx={{
                                    py: 1,
                                    px: 3,
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#FCFCFE',
                                    borderRadius: '5px',
                                }}
                                disabled={formik.isSubmitting}
                                type="submit"
                            >
                                <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                    Save
                                </Typography>
                            </ButtonBase>
                        </Stack>
                    </Stack> </FormikProvider>}
                    {addGeoFence ? '' : showGeo && <DefaultDataGrid rows={arrayData} columns={geoFenceColumns(personnelData.serialNumber, onDeleteClick, onEditClick, setAddGeoFence)} disablePagination
                        onRowClick={(e) => {
                            if (e.row.path) {
                                window.open(e.row.path, '_blank');
                            }
                        }} sx={{ mt: -3 }} />}
                </Stack>
                <Map arrayData={arrayData} isDrawGeoFence={addGeoFence} onDrawGeofence={onDrawGeofence} mode={activeTab} selectedPoligon={selectedPoligon} showTrackerTab={personnelData != null && personnelData.secondaryTracker != null} />
            </Stack >
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            {
                sharingDeleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setSharingDeleteConfirmationShouldOpen(false)
                        updateSharingConfirmAlert(value)
                    }} />)
            }
            {
                documentDeleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDocumentDeleteConfirmationShouldOpen(false)
                        updateDocumentConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 4 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
