import { useEffect, useState } from "react"
import { Filter } from "../../ui/Filter"
import { Stack, Box, MenuItem, Backdrop, CircularProgress } from "@mui/material"
import api from "../../lib/axios"

export const AssetMapViewFilter = ({ arrayAssetData, query, isAssetOrNode, setFilteredArrayData, setArrayData, setArrayNodeData, arrayNodeData, isFilter }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedGroup, setSelectedGroup] = useState(-1)
    const [selectedEvent, setSelectedEvent] = useState(-1)
    const [selectedTracker, setSelectedTracker] = useState(-1)
    const [selectedNodeStatus, setSelectedNodeStatus] = useState(-1)
    const [selectedNodeGroup, setSelectedNodeGroup] = useState(-1)
    const [selectedNodeEvent, setSelectedNodeEvent] = useState(-1)
    const [selectedNodeSensor, setSelectedNodeSensor] = useState(-1)
    const [arrayAssetGroups, setArrayAssetGroups] = useState([]);
    const [arrayNodeGroups, setArrayNodeGroups] = useState([]);
    const [arrayTrackers, setArrayTrackers] = useState([]);
    const [arrayNodeSensor, setArraySensor] = useState([]);
    const assetStatus = ['Idle', 'Moving'];
    const nodeStatus = ['Active', 'Offline'];

    useEffect(() => {
        const fetchData = () => {
            if (!isAssetOrNode) {
                getAssetAllDetail();
            } else {
                getNodeAllDetail();
            }
        };
        setIsLoading(true)
        fetchData();

        // Set up an interval to fetch data and log every 30 seconds
        const intervalId = setInterval(() => {
            console.log('30 seconds have passed. Fetching data again...');
            setIsLoading(false)
            fetchData();
        }, 30000); // 30000 ms = 30 seconds

        // Clean up the interval when the component is unmounted or when isAssetOrNode changes
        return () => clearInterval(intervalId);

    }, [isAssetOrNode]);

    useEffect(() => {
        if (!isAssetOrNode) {
            var filterArray = applyFilters(arrayAssetData, query, selectedStatus, selectedGroup, selectedTracker, selectedEvent)
            setFilteredArrayData(filterArray)
        } else {
            var filterArrayNode = applyFilters(arrayNodeData, query, selectedNodeStatus, selectedNodeGroup, selectedNodeSensor, selectedNodeEvent)
            setFilteredArrayData(filterArrayNode)
        }
    }, [query]);

    const getData = () => {
        // setIsLoading(true)
        api.get('/apis/Assets/trackers/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var filterArray = applyFilters(resData, query, selectedStatus, selectedGroup, selectedTracker, selectedEvent)
                setArrayData(resData)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getNodeData = () => {
        // setIsLoading(true)
        api.get('/apis/Nodes/sensors/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                var filterArray = applyFilters(resData, query, selectedNodeStatus, selectedNodeGroup, selectedNodeSensor, selectedNodeEvent)
                console.log("filterData", filterArray)
                setArrayNodeData(resData)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getAssetAllDetail = () => {
        // setIsLoading(true)
        api.get('/apis/Assets/getFilterDetail/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayAssetGroups(resData.arrayGroup)
                setArrayTrackers(resData.arrayTracker)
            }
            getData();
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const applyFilters = (arrayDataAll, query, selectedStatus, selectedGroup, selectedTracker, selectedEvent) => {
        if (!isAssetOrNode) {
            var finalArray = []
            var filteredArraySearch = arrayDataAll
                .filter((dataObj) => {
                    let matches = true;
                    const properties = ['assetName', 'serialNumber'];
                    let containsQuery = false;
                    properties.forEach((property) => {
                        var str = dataObj[property] + ""
                        if (str.toLowerCase().includes(query.toLowerCase())) {
                            containsQuery = true;
                        }
                    });
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

            if (selectedStatus !== null && selectedStatus != -1) {
                var filteredArrayStatus = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        var speedStatus = dataObj.tracker != null && dataObj.tracker.positionSpeed != null && dataObj.tracker.positionSpeed > 0 ? "Moving" : "Idle"
                        if (selectedStatus == speedStatus) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...filteredArrayStatus]
            }

            if (selectedTracker !== null && selectedTracker != -1) {
                var filteredArrayTracker = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;

                        if (dataObj.serialNumberID === selectedTracker) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...filteredArrayTracker]
            }

            if (selectedGroup !== null && selectedGroup != -1) {
                var filteredArrayGroup = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        if (dataObj.groups != null && dataObj.groups.length > 0) {
                            if (dataObj.groups.includes(selectedGroup)) {
                                containsQuery = true;
                            }
                        }
                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...filteredArrayGroup]
            }
            if (selectedEvent !== null && selectedEvent != -1) {
                var fliterArrayType = finalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;

                        if (dataObj.typeID === selectedEvent) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                finalArray = [...fliterArrayType]
            }
            if (query === "" && selectedStatus === -1 && selectedGroup === -1 && selectedTracker === -1 && selectedEvent === -1) {
                return arrayDataAll
            }
            return finalArray

        } else {
            var nodeFinalArray = []
            var nodefilteredArraySearch = arrayDataAll
                .filter((dataObj) => {
                    let matches = true;
                    const properties = ['nodeName', 'sensorNumber'];
                    let containsQuery = false;
                    properties.forEach((property) => {
                        var str = dataObj[property] + ""
                        if (str.toLowerCase().includes(query.toLowerCase())) {
                            containsQuery = true;
                        }
                    });
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            nodeFinalArray = nodefilteredArraySearch.length > 0 ? [...nodefilteredArraySearch] : []

            if (selectedGroup !== null && selectedGroup != -1) {
                console.log("selectedNodeGroups", selectedGroup);

                var nodefilteredArrayGroups = nodeFinalArray
                    .filter((dataObj) => {
                        console.log("dataObj", dataObj);
                        let matches = true;
                        let containsQuery = false;
                        if (dataObj.groups != null && dataObj.groups.length > 0) {
                            if (dataObj.groups.includes(selectedGroup)) {
                                containsQuery = true;
                            }
                        }
                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                nodeFinalArray = [...nodefilteredArrayGroups]
            }

            if (selectedStatus !== null && selectedStatus != -1) {
                var nodefilteredArrayStatus = nodeFinalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;
                        var speedStatus = dataObj.sensor != null && dataObj.sensor.state != null && dataObj.sensor.state == "1" ? "Active" : "Offline"
                        if (selectedStatus == speedStatus) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                nodeFinalArray = [...nodefilteredArrayStatus]
            }

            if (selectedTracker !== null && selectedTracker != -1) {
                var filteredArraySensor = nodeFinalArray
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;

                        if (dataObj.sensorNumberID === selectedTracker) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
                nodeFinalArray = [...filteredArraySensor]
            }

            if (query === "" && selectedStatus === -1 && selectedGroup === -1 && selectedTracker === -1 && selectedEvent === -1) {
                return arrayDataAll
            }
            return nodeFinalArray
        }
    }

    const getNodeAllDetail = () => {
        // setIsLoading(true)
        api.get('/apis/Nodes/getFilterDetail/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayNodeGroups(resData.arrayGroup)
                setArraySensor(resData.arraySensor)
            }
            getNodeData()
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <Stack>
            {!isAssetOrNode && isFilter &&
                <Stack ml={'10px'} mt={'5px'} mr={'10px'} gap={'10px'}>
                    {console.log("IF")}
                    <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedGroup(value)
                            var assetFilterArray = applyFilters(arrayAssetData, query, selectedStatus, value, selectedTracker, selectedEvent)
                            setFilteredArrayData(assetFilterArray)
                        }}
                        defaultValue={selectedGroup}
                        placeholder="All Groups"
                    >
                        {arrayAssetGroups.map((item, index) => (
                            <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                        ))}
                    </Filter>
                    <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedStatus(value)
                            var assetFilterArray = applyFilters(arrayAssetData, query, value, selectedGroup, selectedTracker, selectedEvent)
                            setFilteredArrayData(assetFilterArray)
                        }}
                        defaultValue={selectedStatus}
                        placeholder="All Status"
                    >
                        {assetStatus.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filter>
                    <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedTracker(value)
                            var filterArray = applyFilters(arrayAssetData, query, selectedStatus, selectedGroup, value, selectedEvent)
                            setFilteredArrayData(filterArray)
                        }}
                        defaultValue={selectedTracker}
                        placeholder="All Devices"
                    >
                        {arrayTrackers.map((item, index) => (
                            <MenuItem key={index} value={item._id}> {item.deviceSerialNumber}</MenuItem>
                        ))}
                    </Filter>
                    {/* <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedEvent(value)
                            // var filterArray = applyFilters(arrayAssetData, query, selectedStatus, value, selectedTracker, selectedEvent)
                            // setFilteredArrayData(filterArray)
                        }}
                        defaultValue={selectedEvent}
                        placeholder="All Events"
                    >
                        {assetStatus.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filter> */}
                </Stack>
            }

            {isAssetOrNode && isFilter &&
                <Stack ml={'10px'} mt={'5px'} mr={'10px'} gap={'10px'}>
                    {console.log("ELSE")}
                    <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedNodeGroup(value)
                            var nodeFilterArray = applyFilters(arrayNodeData, query, selectedNodeStatus, value, selectedNodeSensor, selectedNodeEvent)
                            setFilteredArrayData(nodeFilterArray)
                        }}
                        defaultValue={selectedNodeGroup}
                        placeholder="All Groups"
                    >
                        {arrayNodeGroups.map((item, index) => (
                            <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                        ))}
                    </Filter>
                    <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedNodeStatus(value)
                            var nodeFilterArray = applyFilters(arrayNodeData, query, value, selectedNodeGroup, selectedNodeSensor, selectedNodeEvent)
                            setFilteredArrayData(nodeFilterArray)

                        }}
                        defaultValue={selectedNodeStatus}
                        placeholder="All Status"
                    >
                        {nodeStatus.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filter>
                    <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedNodeSensor(value)
                            var nodeFilterArray = applyFilters(arrayNodeData, query, selectedNodeStatus, selectedNodeGroup, value, selectedNodeEvent)
                            setFilteredArrayData(nodeFilterArray)
                        }}
                        defaultValue={selectedNodeSensor}
                        placeholder="All Devices"
                    >
                        {arrayNodeSensor.map((item, index) => (
                            <MenuItem key={index} value={item._id}> {item.sensorNumber}</MenuItem>
                        ))}
                    </Filter>
                    {/* <Filter
                        sx={{
                            '.MuiSelect-select': {
                                py: '5px',
                                fontSize: 12,
                                pl: 2,
                            },
                        }}
                        onChange={(event) => {
                            var value = event.target.value
                            setSelectedNodeEvent(value)
                            // var filterArray = applyFilters(arrayAssetData, query, selectedNodeStatus, value, selectedNodeSensor, selectedEvent)
                            // setFilteredArrayData(filterArray)
                        }}
                        defaultValue={selectedNodeEvent}
                        placeholder="All Events"
                    >
                        {assetStatus.map((item, index) => (
                            <MenuItem key={index} value={item}> {item}</MenuItem>
                        ))}
                    </Filter> */}
                </Stack>
            }
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    )
}