import { arrayDeviceType, arrayDeviceProduct } from '../../RuleNew/data/arrayData';
import { Box, Stack, Typography } from '@mui/material';
export const columns = [
    {
        field: 'deviceSerialNumber',
        headerName: 'Device ID',
        width: 210,
        flex: 1,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>
                {value ? value : "-"}
            </Typography>
        ),
    },
    {
        field: 'name', flex: 1, headerName: 'Associated', width: 200,
        renderCell: (value) => (
            <Typography fontSize={12}>{value.row != null && value.row.name ? value.row.name : "-"}</Typography>
        ),
    },
    {
        field: 'type', flex: 1, headerName: 'Item Type', width: 135,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>
                {value == "1" ? "Asset" : "Node"}
            </Typography>
        ),
    },
    {
        field: 'manufacturer', flex: 1, headerName: 'Manufacturer', width: 135,
        renderCell: (params) => (
            <Typography fontSize={12}>
                {params.row.type == "1" ? "Digital Matter" : (params.row.sensor ? arrayDeviceProduct.find((manufacturer) => manufacturer.id == params.row.sensor.manufacturer)?.value : "-")}
            </Typography>
        ),
    },
    {
        field: 'product', flex: 1, headerName: 'Product', width: 135,
        renderCell: (params) => {
            return (
                <Typography fontSize={12}>
                    {params.row.type == "1" ? (params.row.tracker.type ? params.row.tracker.type : "-") : (params.row.sensor ? arrayDeviceType.find((type) => type.id == params.row.sensor.product)?.value : "-")}
                </Typography>
            )
        },
    },
    {
        field: 'amount', headerName: 'Amount', width: 110,
        valueGetter: (params) => params.row.cost != null && params.row.cost != "" ? parseFloat(params.row.cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : "0.00",
        renderCell: (params) => (
            <Typography fontSize={12}>${params.value}</Typography>
        ),
    },
    {
        field: 'status',
        headerName: 'Billing Status',
        width: 170,
        renderCell: ({ value }) => (
            <Box
                sx={{
                    px: 2,
                    border: '1px solid #E2E2EA',
                    borderRadius: '5px',
                    height: '30px',
                    width: '100px',

                }}
            >
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    height="100%"
                >
                    <Typography
                        color={'#44444F'}
                        fontFamily={'Roboto'}
                        fontWeight={'Regular'}
                        fontSize={12}
                    >
                        {(value != null && value == "1" ? "Active" : "Disabled")}
                    </Typography>
                    <Box
                        sx={{
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            backgroundColor: value != null && value == "1" ? "#32D9A2" : "#FF3941",
                        }}
                    />
                </Stack>
            </Box>
        ),
    },
];

