import { ButtonBase, Stack, Typography, Backdrop, CircularProgress, Box, IconButton, Switch } from "@mui/material"
import React, { useState, useEffect, useRef } from 'react';
import { Datepicker } from '../../../ui/Datepicker/index.js';
import api from '../../../lib/axios.js';
import moment from 'moment'
import dayjs from 'dayjs';
import { DefaultDataGrid } from '../../../ui/DefaultDataGrid/index.js';
import { columns, columnsVibration, configColumns } from "./Data/data.jsx";
import excelFile from '../../../assets/images/excelFile.svg';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { DefaultToggle } from "./DefaultToggle.jsx"
import { enqueueSnackbar } from 'notistack';
import Papa from 'papaparse';
import SettingsIcon from '@mui/icons-material/Settings';
import { PlusIcon } from "../../../assets/icons/PlusIcon.jsx";
import { ConfigDataManage } from "./ConfigDataManage.jsx";
import { Refresh } from "@mui/icons-material";
import { arrayDataType } from "./Data/configArrayData.jsx";

export const NodeData = ({ sensorData, selectedDataID }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const intervalRef = useRef(null);
    const [arrayNodeDate, setArrayNodeDate] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTableData, setSelectedTableData] = useState(true);
    const [selectedRowData, setSelectedRowData] = useState(false);
    const [selectedConfigData, setSelectedConfigData] = useState(false);
    const [isAutoRefresh, setIsAutoRefresh] = useState(false);
    const [isForConfigManage, setIsForConfigManage] = useState(false);
    const [jsonRawData, setJsonRawData] = useState("");
    const [timeStamp, setTimeStamp] = useState("");
    const [arrayData, setArrayData] = useState([])
    const [secondaryDataId, setSecondaryDataId] = useState("")

    useEffect(() => {
        getNodeData()
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    }, []);

    useEffect(() => {
        if (isAutoRefresh) {
            intervalRef.current = setInterval(() => {
                getNodeData();
                console.log("remaining");
            }, 30000);

        } else {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        }
    }, [isAutoRefresh]);

    const getNodeData = () => {
        if (sensorData == null) {
            return
        }
        setIsLoading(true)
        let url = '/apis/NodeSensors/rawData/' + sensorData.sensorNumber
        if (sensorData.product == "4") {
            url = '/apis/NodeSensors/vibrationRawData/' + sensorData.sensorNumber
        }
        api.get(url).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                const arrayTableData = resData.arrayTableData.map((item, index) => {
                    return { ...item, id: index + 1 };
                });
                setArrayNodeDate(arrayTableData)
                if (arrayTableData.length > 0) {
                    let firstTimeStamp = arrayTableData[0].timestamp;
                    if (sensorData.product == "4") {
                        firstTimeStamp = arrayTableData[0].received;
                    }
                    setTimeStamp(firstTimeStamp)
                }
                if (resData.arrayrawData.length > 0) {
                    console.log("arrayRawData", resData.arrayrawData);
                    const formattedData = JSON.stringify(resData.arrayrawData, null, 2);
                    setJsonRawData(formattedData)
                } else {
                    setJsonRawData("")
                }
                getData()
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleSelecteTableData = () => {
        setSelectedTableData(true)
        // setIsAutoRefresh(false)
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(jsonRawData)
            .then(() => {
                console.log('Text copied to clipboard');
                enqueueSnackbar('Content Copied', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
            })
            .catch(err => {
                console.error('Failed to copy text: ', err);
            });
    };

    const getData = () => {
        setIsLoading(true)
        api.get('/apis/SecondaryDatas/byNode/' + selectedDataID).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                setArrayData(data)
                // console.log("Secondary Datas", data);
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleExportTableData = () => {
        if (sensorData.product == "4") {
            const updatedArrayData = arrayNodeDate.map((res) => {
                const temp = res.temperature.toFixed(1);
                const rms_ACC_G = `${res.xRmsACCG} ${res.yRmsACCG} ${res.zRmsACCG}`;
                const max_ACC_G = `${res.xMaxACCG} ${res.yMaxACCG} ${res.zMaxACCG}`;
                const velocity_mm_sec = `${res.xVelocityMmSec} ${res.yVelocityMmSec} ${res.zVelocityMmSec}`;
                const displacement_mm = `${res.xDisplacementMm} ${res.yDisplacementMm} ${res.zDisplacementMm}`;
                const peak_one_Hz = `${res.xPeakOneHz} ${res.yPeakOneHz} ${res.zPeakOneHz}`;
                const peak_two_Hz = `${res.xPeakTwoHz} ${res.yPeakTwoHz} ${res.zPeakTwoHz}`;
                const peak_three_Hz = `${res.xPeakThreeHz} ${res.yPeakThreeHz} ${res.zPeakThreeHz}`;
                const timeStamp = moment(res.timestamp).format("MM/DD/YY hh:mma")
                return {
                    "Temp.": temp,
                    Battery: res.battery,
                    Rssi: res.rssi,
                    Rms_ACC_G: rms_ACC_G,
                    Max_ACC_G: max_ACC_G,
                    Velocity_mm_sec: velocity_mm_sec,
                    Displacement_mm: displacement_mm,
                    Peak_one_Hz: peak_one_Hz,
                    Peak_two_Hz: peak_two_Hz,
                    Peak_three_Hz: peak_three_Hz,
                    Timestamp: timeStamp,
                };
            });
            const csvData = Papa.unparse(updatedArrayData);

            // Create a Blob object containing the CSV data
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a download link and trigger the click event to download the CSV file
            const link = document.createElement('a');
            link.href = url;
            link.download = `TableData.csv`;
            link.click();

            // Release the Blob URL
            URL.revokeObjectURL(url);
        } else {
            const updatedArrayData = arrayNodeDate.map((res) => {
                const timeStamp = moment(res.timestamp).format("MM/DD/YY hh:mma")
                return { "Temp.": res.temperature, Battery: res.battery, Speed: res.speed, Altitude: res.altitude, "Cell Signal": res.cellSignal, Latitude: res.latitude, Longtitude: res.longitude, Timestamp: timeStamp };
            });
            const csvData = Papa.unparse(updatedArrayData);

            // Create a Blob object containing the CSV data
            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(blob);

            // Create a download link and trigger the click event to download the CSV file
            const link = document.createElement('a');
            link.href = url;
            link.download = `TableData.csv`;
            link.click();

            // Release the Blob URL
            URL.revokeObjectURL(url);
        }
    }

    const handleExportConfigData = () => {
        const updatedArrayData = arrayData.map((res) => {
            const timeStamp = moment(res.timestamp).format("MM/DD/YY hh:mma")
            const dataType = res.dataType ? arrayDataType.find((dataType) => dataType.id == res.dataType)?.name : null
            return { "Input Name": res.name, "Sensor Pin": res.pin, "Conversion Logic": res.logic, "Data Type": dataType, Timestamp: timeStamp };
        });
        const csvData = Papa.unparse(updatedArrayData);

        // Create a Blob object containing the CSV data
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Create a download link and trigger the click event to download the CSV file
        const link = document.createElement('a');
        link.href = url;
        link.download = `Config.csv`;
        link.click();

        // Release the Blob URL
        URL.revokeObjectURL(url);
    }

    return (
        <>
            <Stack
                sx={{
                    flex: 1,
                }}
                maxWidth={'100vw'}
            >
                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack spacing={2} direction={'row'}>
                        <ButtonBase
                            sx={{
                                pt: 1,
                                pb: 1,
                                px: 5,
                                border: '1px solid #E2E2EA',
                                bgcolor: selectedTableData == true ? "#1E75FF" : "#FCFCFE",
                                borderRadius: '5px',
                                height: '30px'
                            }}
                            onClick={() => {
                                handleSelecteTableData()
                                if (!selectedTableData) {
                                    setSelectedTableData(true)
                                    setSelectedRowData(false)
                                    setSelectedConfigData(false)
                                }
                            }}
                        >
                            <Typography fontSize={12} fontFamily={"Roboto"} fontWeight={'Medium'} color={selectedTableData == true ? "#FAFAFB" : "#151D26"}>
                                Tables
                            </Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                pt: 1,
                                pb: 1,
                                px: 5,
                                border: '1px solid #E2E2EA',
                                bgcolor: selectedRowData == true ? "#1E75FF" : "#FCFCFE",
                                borderRadius: '5px',
                                height: '30px'
                            }}
                            onClick={() => {
                                if (!selectedRowData) {
                                    setSelectedRowData(true)
                                    setSelectedTableData(false)
                                    setSelectedConfigData(false)
                                }
                            }}
                        >
                            <Typography fontSize={12} fontFamily={"Roboto"} fontWeight={'Medium'} color={selectedRowData == true ? "#FAFAFB" : "#151D26"}>
                                {`Raw{JSON}`}
                            </Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                pt: 1,
                                pb: 1,
                                px: 5,
                                border: '1px solid #E2E2EA',
                                bgcolor: selectedConfigData == true ? "#1E75FF" : "#FCFCFE",
                                borderRadius: '5px',
                                height: '30px'
                            }}
                            onClick={() => {
                                if (!selectedConfigData) {
                                    setSelectedConfigData(true)
                                    setSelectedTableData(false)
                                    setSelectedRowData(false)
                                    setIsForConfigManage(false)
                                    setSecondaryDataId("")
                                    setIsAutoRefresh(false)
                                } else {
                                    setIsForConfigManage(false)
                                    setSecondaryDataId("")
                                }
                            }}
                        >
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <IconButton
                                    sx={{
                                        height: 10,
                                        width: 10,
                                        pr: 2
                                    }}

                                >
                                    <SettingsIcon sx={{ color: selectedConfigData == true ? "#FFFFFF" : "#151D26", }} />
                                </IconButton >
                                <Typography fontSize={12} fontFamily={"Roboto"} fontWeight={'Medium'} color={selectedConfigData == true ? "#FAFAFB" : "#151D26"}>
                                    Config
                                </Typography>
                            </Stack>
                        </ButtonBase>
                        {selectedRowData == false &&<ButtonBase
                            sx={{
                                height: 30,
                                width: 30,
                                px: 2,
                                py: 1,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '6px',
                            }}
                            onClick={() => {
                                if (selectedTableData) {
                                    handleExportTableData()
                                } else {
                                    handleExportConfigData()
                                }
                            }}
                        >
                            <Box component={'img'} src={excelFile} sx={{ width: "18px", height: "22px" }} />
                        </ButtonBase>}
                    </Stack>
                    <Stack direction={'row'} spacing={1}>
                        {selectedConfigData == false &&< Stack direction={'row'} alignItems={'center'}>
                            <Switch
                                sx={{
                                    alignItems: 'center',
                                    ml: -1,
                                    '.MuiSwitch-thumb': {
                                        bgcolor: 'primary.main',
                                        width: 20,
                                        height: 20,
                                    },
                                    '.Mui-checked .MuiSwitch-thumb': {
                                        backgroundColor: 'green.main',
                                    },
                                    '&.Mui-checked+.MuiSwitch-track, .MuiSwitch-track': {
                                        backgroundColor: '#EAEBEA !important',
                                        boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 16%)',
                                        height: 20,
                                        width: 40,
                                        borderRadius: '10px',
                                    },
                                    '.MuiSwitch-switchBase': {
                                        top: '0px',
                                    },
                                }}
                                checked={isAutoRefresh}
                                onChange={(e) => { setIsAutoRefresh(e.target.checked) }}
                                name={"isAutoRefresh"}
                            />
                            <Typography fontSize={14} fontFamily={"Roboto"} fontWeight='medium'>
                                Auto Refresh (30 secs)
                            </Typography>
                        </Stack>}

                        {selectedConfigData == true &&secondaryDataId == "" &&<ButtonBase
                            sx={{
                                width: 40,
                                height: 30,
                                border: '1px solid #E2E2EA',
                                bgcolor: "#1E75FF",
                                borderRadius: '5px',
                            }}
                            onClick={() => {
                                // setSelectedConfigData(false)
                                setIsForConfigManage(true)
                                setSecondaryDataId("")
                            }}
                        >
                            <PlusIcon sx={{ color: "#FFFFFF" }} ></PlusIcon>
                        </ButtonBase>}
                        {selectedConfigData == false &&<ButtonBase
                            sx={{
                                width: 40,
                                height: 30,
                                border: '1px solid #E2E2EA',
                                bgcolor: "#F6F8FA",
                                borderRadius: '5px',
                            }}
                            onClick={() => { getNodeData() }}
                        >
                            <Refresh ></Refresh>
                        </ButtonBase>}
                    </Stack>
                </Stack>
                {selectedTableData == true &&<Stack
                    width={'100%'}
                    height={'calc(100vh - 288px)'}
                    mt={2}
                    sx={{
                        border: '1px solid #E2E2EA',
                        borderRadius: '8px 0 0 8px',
                        borderRight: 'none',
                        bgcolor: '#FFFFFF',
                        overflow: 'hidden'
                    }}
                >
                    {sensorData.product == "4" ?
                        <DefaultDataGrid rows={arrayNodeDate} columns={columnsVibration} />
                        :
                        <DefaultDataGrid rows={arrayNodeDate} columns={columns} />
                    }
                </Stack>}

                {selectedRowData == true &&<Box
                    mt={2}
                    px={2}
                    py={1}
                    width={'100%'}
                    height={'calc(100vh - 288px)'}
                    bgcolor={'#F6F8FA'}
                    borderRadius={'12px'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        overflowY: 'auto',

                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            width: '4px',
                            backgroundColor: '#bdbdbd',
                        },
                    }}
                >
                    <Stack direction={'row'} spacing={2} justifyContent={'space-between'}>
                        <Stack direction={'row'} spacing={1} mt={1}>
                            <Typography
                                fontSize={12}
                                color={'#A0A3A6'}
                                fontFamily={"Roboto"}
                                fontWeight='regular'>Last Update:</Typography>
                            <Typography fontSize={12} fontFamily={"Roboto"} fontWeight='medium'>
                                {timeStamp ? moment(timeStamp).format("MM/DD/YY hh:mma") : "-"}
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1} mt={1} mr={4} alignItems={'center'}>
                            <ButtonBase
                                sx={{
                                    pt: 1,
                                    pb: 1,
                                    px: 5,
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#FCFCFE',
                                    borderRadius: '5px',
                                }}
                                onClick={handleCopy}
                            >
                                <Typography fontSize={14} fontFamily={"Roboto"} fontWeight='medium'>
                                    Copy
                                </Typography>
                            </ButtonBase>
                            {/* <DefaultToggle name="isAutoRefresh" checked={isAutoRefresh} onChange={(e) => { setIsAutoRefresh(e.target.checked) }} />
                            <Typography fontSize={14} fontFamily={"Roboto"} fontWeight='medium'>
                                Auto Refresh (30 secs)
                            </Typography> */}
                        </Stack>
                    </Stack>
                    <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}>
                        {jsonRawData}
                    </Typography>
                </Box>}

                {isForConfigManage == false &&selectedTableData == false &&selectedRowData == false &&<Stack
                    width={'100%'}
                    height={'calc(100vh - 280px)'}
                    mb={1}
                    mt={2}
                    sx={{
                        border: '1px solid #E2E2EA',
                        borderRadius: '8px 0 0 8px',
                        borderRight: 'none',
                        bgcolor: '#FFFFFF',
                        overflow: 'hidden'
                    }}
                >
                    <DefaultDataGrid rows={arrayData} columns={configColumns} onRowClick={(e) => {
                        setSecondaryDataId(e.row._id)
                        setIsForConfigManage(true)
                    }} />
                </Stack>}

                {isForConfigManage == true &&<ConfigDataManage selectedDataId={secondaryDataId} nodeID={selectedDataID} onClose={(e) => {
                    setSelectedConfigData(true)
                    setIsForConfigManage(false)
                    setSecondaryDataId("")
                    if (e) {
                        getData()
                    }
                }} ></ConfigDataManage>}
            </Stack >
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )

}