import { Stack, Typography, Box } from '@mui/material';
import { getPriorityColor } from '../../../core/functions/statusHelpers.js';
import { ArrowForwardIos } from '@mui/icons-material';
import { arrayMeasurment } from '../../RuleNew/data/arrayData.jsx';

export const columns = [
    {
        field: 'name',
        headerName: 'Asset/Node',
        width: 200,
        // flex: 1,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.name ? value.row.name : '-'}
            </Typography>
        ),
    },
    {
        field: 'eventID',
        headerName: 'Event ID',
        width: 100,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.eventID ? value.row.eventID : '-'}
            </Typography>
        ),
    },
    {
        field: 'ruleName',
        headerName: 'Rule',
        width: 120,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>
                {value || "-"}
            </Typography>
        ),
    },
    {
        field: 'eventType',
        headerName: 'Measurement',
        width: 120,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{arrayMeasurment.find(obj => obj.id == value)?.value || "-"}</Typography>
        ),
    },
    {
        field: 'eventText',
        headerName: 'Condition',
        width: 180,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.eventText ? value.row.eventText : '-'}
            </Typography>
        ),
    },
    {
        field: 'value',
        headerName: 'Data Value',
        width: 140,
        renderCell: (params) => (
            <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                <Typography fontSize={12}>{params.row.value}</Typography>
                <Box
                    sx={{
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                        backgroundColor: getPriorityColor(params.row.value == "0" ? "3" : params.row.value),
                    }}
                />
            </Stack>
        ),
    },
    {
        field: 'createdAt',
        headerName: 'Timestamp',
        width: 190,
        renderCell: ({ value }) => (
            <Typography fontSize={12}>{value}</Typography>
        )
    },
    {
        field: 'arrowIcon',
        headerName: '',
        flex: 1,
        renderCell: () => (
            <Stack direction={'row'} justifyContent={'flex-end'} pr={5} alignItems={'center'} width={'100%'} gap={1}>
                <ArrowForwardIos
                    sx={{
                        color: 'Black',
                        width: 18,
                        height: 18,
                    }}
                />
                <Typography color={'Black'} fontSize={12}>
                    Details
                </Typography>
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];

export const rows = [
    {
        id: 1,
        asset: 'TR-500-14A',
        eventId: '1002',
        sensor: 'GPS Device',
        type: 'Internal Temperature',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 2,
        asset: 'TR-500-14A',
        eventId: '1003',
        sensor: 'GPS Device',
        type: 'Speed',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 3,
        asset: 'TR-500-14A',
        eventId: '1004',
        sensor: 'GPS Device',
        type: 'Altitude',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 1,
        },
    },
    {
        id: 4,
        asset: 'TR-500-14A',
        eventId: '1005',
        sensor: 'GPS Device',
        type: 'Internal Temperature',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 5,
        asset: 'TR-500-14A',
        eventId: '1006',
        sensor: 'GPS Device',
        type: 'Internal Temperature',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 3,
        },
    },
    {
        id: 6,
        asset: 'TR-500-14A',
        eventId: '1007',
        sensor: 'GPS Device',
        type: 'Speed',
        timestamp: '06-10-2023 1:23AM',
        threshold: 'Below Min.',
        dataValue: {
            text: '68.5 °F',
            code: 1,
        },
    },
];

export const arrayAllAssetNode = [

    { id: 1, value: 'All Asset' },

    { id: 2, value: 'All Node' }
];

export const arrayDevice = [
    { id: 1, value: "HAWK" },
    { id: 2, value: "FlexSense" }
]
