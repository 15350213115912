import { Backdrop, Box, ButtonBase, CircularProgress, Stack, Typography, MenuItem, LinearProgress, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SearchInput } from '../../components/SearchInput/index.js';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { columns } from './data/nodes.jsx';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios.js';
import { Filter } from '../../ui/Filter/Filter.jsx';
import Papa from 'papaparse';
import plusIcon from '../../assets/images/plusIcon.svg';
import plusIconWhite from '../../assets/images/plusIconWhite.svg';
import excelFile from '../../assets/images/excelFile.svg';
import moment from 'moment';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { enqueueSnackbar } from 'notistack';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { DefaultSearchInput } from '../../ui/DefaultSearchInput/DefaultSearchInput.jsx';

export const NodeListing = () => {
    const navigate = useNavigate();
    const [arrayData, setArrayData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(-1)
    const [selectedGroup, setSelectedGroup] = useState(-1)
    const [selectedCategory, setSelectedCategory] = useState(-1)
    const [selectedType, setSelectedType] = useState(-1)
    const [arrayGroups, setArrayGroups] = useState([]);
    const [arrayCategory, setArrayCategory] = useState([]);
    const [arrayType, setArrayType] = useState([]);
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arraySummeryGroups, setArraySummeryGroups] = useState([]);
    const arrayTopStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Active", totals: 0, color: "#32D9A2" },
        { id: "2", name: "Events", totals: 0, color: "#1E75FF" },
        { id: "3", name: "Anomalies", totals: 0, color: "#FFAD2C" },
        { id: "4", name: "Offline", totals: 0, color: "#979797" },
    ]

    const assetStatus = ['Online', 'Offline'];

    useEffect(() => {
        getAllDetail()
        getSummery()
        getData()
    }, []);
    const getSummery = async () => {
        // setIsLoading(true)
        api.get('/apis/Nodes/summery').then(async (res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArraySummeryGroups(resData.arrayGroup)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getAllDetail = () => {
        // setIsLoading(true)
        api.get('/apis/Nodes/getFilterDetail/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayGroups(resData.arrayGroup)
                setArrayCategory(resData.arrayCategory)
                setArrayType(resData.arrayType)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = () => {
        setIsLoading(true)
        api.get('/apis/Nodes/sensors/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                var filterArray = applyFilters(resData, query, selectedStatus, selectedGroup, selectedCategory, selectedType)
                console.log(filterArray)
                setArrayData(resData)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const applyFilters = (arrayDataAll, query, selectedStatus, selectedGroup, selectedCategory, selectedType) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['nodeName', 'serialNumber'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

        if (selectedStatus !== null && selectedStatus != -1) {
            var filteredArrayStatus = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    var speedStatus = dataObj.sensor != null && dataObj.sensor.state != null && dataObj.sensor.state == "1" ? "Online" : "Offline"
                    if (selectedStatus == speedStatus) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayStatus]
        }

        if (selectedGroup !== null && selectedGroup != -1) {
            var filteredArrayGroup = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;
                    if (dataObj.groups != null && dataObj.groups.length > 0) {
                        if (dataObj.groups.includes(selectedGroup)) {
                            containsQuery = true;
                        }
                    }
                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...filteredArrayGroup]
        }
        if (selectedCategory !== null && selectedCategory != -1) {
            var fliterArrayCategory = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.nodeCategoryID === selectedCategory) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...fliterArrayCategory]
        }
        if (selectedType !== null && selectedType != -1) {
            var fliterArrayType = finalArray
                .filter((dataObj) => {
                    let matches = true;
                    let containsQuery = false;

                    if (dataObj.nodeTypeID === selectedType) {
                        containsQuery = true;
                    }

                    if (!containsQuery) {
                        matches = false;
                    }
                    return matches;
                });
            finalArray = [...fliterArrayType]
        }
        if (query === "" && selectedStatus === -1 && selectedGroup === -1 && selectedCategory === -1 && selectedType === -1) {
            return arrayDataAll
        }
        return finalArray
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayData, event.target.value, selectedStatus, selectedGroup, selectedCategory, selectedType)
        setFilteredArrayData(filterArray)
    };

    function pieChart(chartID, percentageInSummery, color, name) {
        am5.addLicense("AM5C349425111");
        am5.array.each(am5.registry.rootElements, function (root) {
            // console.log("root.dom.id", root.dom.id);
            // console.log("chartID", chartID);

            if (root && root.dom && root.dom.id == chartID) {
                root.dispose();
            }
        });
        let root = am5.Root.new(chartID);


        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        let chart = null
        if (chartID == 'chartdiv') {
            chart = root.container.children.push(am5percent.PieChart.new(root,
                {
                    innerRadius: am5.percent(90),
                    layout: root.verticalLayout
                }));
        } else {
            chart = root.container.children.push(am5percent.PieChart.new(root,
                {
                    innerRadius: am5.percent(91),
                    layout: root.verticalLayout
                }));
        }


        let series = chart.series.push(am5percent.PieSeries.new(root, {
            valueField: "size",
            categoryField: "sector",
        }));

        series.slices.template.set("tooltipText", "");
        series.slices.template.set("toggleKey", "none");
        series.labels.template.set("visible", false);
        series.ticks.template.set("visible", false);

        let label = root.tooltipContainer.children.push(am5.Label.new(root, {
            x: am5.p50,
            y: am5.p50,
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color(0x000000),
            fontSize: 20
        }));

        if (chartID == 'chartdiv') {
            series.get("colors").set("colors", [
                am5.color(0x1E75FF),
                am5.color(0xE6E7E8),
            ]);
            series.data.setAll([
                { sector: "", size: 0 },
                { sector: "", size: 100 },
            ]);
            label.set("text", "0%");

            if (!isNaN(percentageInSummery) && percentageInSummery != 0) {
                const remainingValue = 100 - percentageInSummery;
                console.log("remainingValue", remainingValue)
                series.data.setAll([
                    { sector: "", size: percentageInSummery },
                    { sector: "", size: remainingValue },
                ]);
                label.set("text", `${percentageInSummery}%`);
            } else {
                series.get("colors").set("colors", [
                    am5.color(0x1E75FF),
                    am5.color(0xE6E7E8),
                ]);
                series.data.setAll([
                    { sector: "", size: 0 },
                    { sector: "", size: 100 },
                ]);
                label.set("text", "0%");
            }
        } else {
            if (!isNaN(percentageInSummery) && percentageInSummery != 0) {
                series.get("colors").set("colors", [
                    am5.color(color),
                    am5.color(0xE6E7E8),
                ]);

                const remainingValue = 100 - percentageInSummery;
                console.log("remainingValue", remainingValue)
                series.data.setAll([
                    { sector: name, size: percentageInSummery },
                    { sector: "", size: remainingValue },
                ]);
                label.set("text", `${percentageInSummery}%`);
            } else {
                series.get("colors").set("colors", [
                    am5.color(0x1E75FF),
                    am5.color(0xE6E7E8),
                ]);

                series.data.setAll([
                    { sector: "", size: 0 },
                    { sector: "", size: 100 },
                ]);
                label.set("text", "0%");
            }
        }

        series.appear(1000, 100);
    }

    function onCheck(nodeData, isFavourite) {
        setIsLoading(true)
        var data = {
            sensorNumberID: nodeData.sensorNumberID,
            sensorNumber: nodeData.sensorNumber,
            isFavourite: isFavourite
        }
        api.put('/apis/Nodes/' + nodeData._id, data).then((res) => {

            getData()
        }).catch(function (error) {
            console.log(error);
        });
    }

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);

        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        return durationString.trim();
    }

    return (
        <Stack width={'100%'} mb={1}>
            <Stack px={3} pt={3}>
                <Stack direction={'row'} gap={5} alignItems={'center'} >
                    <Stack>
                        <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                            NODES
                        </Typography>
                        <Stack direction={'row'} alignItems={'center'} gap={5} >
                            {arrayTopStatusData.map((status) => (
                                <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                    <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                        {status.name}
                                    </Typography>
                                    <Box
                                        sx={{
                                            width: 8,
                                            height: 8,
                                            borderRadius: '50%',
                                            backgroundColor: status.color,
                                            mt: '16px'
                                        }}
                                    />
                                    <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                        {status.id == "0" ? arrayData.length : status.id == "1" ? arrayData.filter((data) => data.sensor.state == "1").length : status.id == "2" ? arrayData.filter((data) => data.isEvent == "1").length : status.id == "4" ? arrayData.filter((data) => data.sensor.state == "0").length : status.totals}
                                    </Typography>
                                </Stack>
                            ))}
                        </Stack>
                    </Stack>
                    <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                    <Stack width={"100%"}>
                        <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                            {arraySummeryGroups.length > 0 ? "TOP METRICS" : ""}
                        </Typography>
                        {arraySummeryGroups.length > 0 ?
                            <Grid container xs={12} columnSpacing={"26px"}>
                                {arraySummeryGroups.map((obj) => {
                                    return (
                                        <Grid item xs={2.4} key={obj._id}>
                                            <Stack>
                                                <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                    {obj.name + " " + `${obj.percentage}%`}
                                                </Typography>
                                                <LinearProgress
                                                    sx={{
                                                        width: '100%',
                                                        height: "6px",
                                                        flexShrink: 0,
                                                        borderRadius: '2px',
                                                        bgcolor: '#EFEFEF',
                                                        mt: "17px",
                                                        '.MuiLinearProgress-bar': {
                                                            borderRadius: '3px',
                                                            bgcolor: obj.color ? obj.color : "",
                                                        },
                                                    }}
                                                    variant={'determinate'}
                                                    value={obj.percentage ? obj.percentage : 0}
                                                />
                                                <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                    {obj.assignedNode}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                            : <Stack height="70px" />}
                    </Stack>
                </Stack>
                <Stack direction={'row'} gap={1} mt={"32px"}>
                    <DefaultSearchInput name={'search'} handleQueryChange={handleQueryChange} sx={{ minWidth: '900px' }} />
                    {/* <Box maxWidth={160} width={'100%'}>
                        <Filter
                            sx={{
                                '.MuiSelect-select': {
                                    py: '5px',
                                    fontSize: 12,
                                    pl: 2,
                                },
                            }}
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedGroup(value)
                                var filterArray = applyFilters(arrayData, query, selectedStatus, value, selectedCategory, selectedType)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedGroup}
                            placeholder="All Groups"
                        >
                            {arrayGroups.map((item, index) => (
                                <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <Box maxWidth={150} width={'100%'}>
                        <Filter
                            sx={{
                                '.MuiSelect-select': {
                                    py: '5px',
                                    fontSize: 12,
                                    pl: 2,
                                },
                            }}
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedStatus(value)
                                var filterArray = applyFilters(arrayData, query, value, selectedGroup, selectedCategory, selectedType)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedStatus}
                            placeholder="All Node Status"
                        >
                            {assetStatus.map((item, index) => (
                                <MenuItem key={index} value={item}> {item}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <Box maxWidth={160} width={'100%'}>
                        <Filter
                            sx={{
                                '.MuiSelect-select': {
                                    py: '5px',
                                    fontSize: 12,
                                    pl: 2,
                                },
                            }}
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedCategory(value)
                                var filterArray = applyFilters(arrayData, query, selectedStatus, selectedGroup, value, selectedType)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedCategory}
                            placeholder="All Categories"
                        >
                            {arrayCategory.map((item, index) => (
                                <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                            ))}
                        </Filter>
                    </Box>
                    <Box maxWidth={160} width={'100%'}>
                        <Filter
                            sx={{
                                '.MuiSelect-select': {
                                    py: '5px',
                                    fontSize: 12,
                                    pl: 2,
                                },
                            }}
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedType(value)
                                var filterArray = applyFilters(arrayData, query, selectedStatus, selectedGroup, selectedCategory, value)
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedType}
                            placeholder="All Node Types"
                        >
                            {arrayType.map((item, index) => (
                                <MenuItem key={index} value={item._id}> {item.name}</MenuItem>
                            ))}
                        </Filter>
                    </Box> */}
                    <ButtonBase
                        sx={{
                            height: 30,
                            pl: '10px',
                            pr: '21px',
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #E2E2EA',
                            bgcolor: '#1E75FF',
                            gap: 1,
                            borderRadius: '6px',
                        }}
                        onClick={() => navigate('/node-listing/manage', { state: { id: "" } })}
                    >
                        <Stack direction={'row'} alignItems={'center'} width={'100%'} gap={'11px'}>
                            <Box component={'img'} src={plusIconWhite} />
                            <Typography fontSize={12} color={'#FFFFFF'} fontWeight={400} fontFamily={'Roboto'}>
                                Create
                            </Typography>
                        </Stack>
                    </ButtonBase>
                    {/* <ButtonBase
                        sx={{
                            height: 30,
                            width: 30,
                            px: 2,
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            border: '1px solid #E2E2EA',
                            bgcolor: '#F6F8FA',
                            borderRadius: '6px',
                        }}
                        onClick={() => {
                            if (filteredArrayData.length > 0) {
                                const updatedArrayData = filteredArrayData.map((res) => {
                                    const date = res.tracker && res.tracker.timestamp ? res.tracker.timestamp : null
                                    var formatedDate = date != null ? calculateDuration(date, moment().format("MM/DD/YY hh:mm a")) : "-"

                                    var arrayGroup = res.groupData
                                    var groupText = arrayGroup.map(group => group.name).join(',')

                                    return { Node: res.nodeName, Status: speedStatus, Sensor: res.sensor.sensorNumber, Groups: groupText, "Last Updated": formatedDate };
                                });
                                const csvData = Papa.unparse(updatedArrayData);

                                const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

                                const url = URL.createObjectURL(blob);

                                const link = document.createElement('a');
                                link.href = url;
                                link.download = `Node.csv`;
                                link.click();

                                URL.revokeObjectURL(url);
                            } else {
                                enqueueSnackbar("There is no data to export", {
                                    anchorOrigin: {
                                        horizontal: 'right',
                                        vertical: 'top'
                                    },
                                    variant: 'error'
                                });
                            }
                        }}
                    >
                        <Box component={'img'} src={excelFile} sx={{ width: "18px", height: "22px" }} />
                    </ButtonBase> */}
                </Stack>
            </Stack>
            <Stack flex={1} borderTop={'1px solid #E1E3E8'} direction={'row'} mt={'18px'}>
                <DefaultDataGrid rows={filteredArrayData} columns={columns(calculateDuration, onCheck)} onRowClick={(e) => navigate(`/node-listing/detail`, { state: { id: e.row._id } })} sx={{
                    '.MuiDataGrid-columnHeaders': {
                        border: 'none',
                        borderRadius: '5px',
                        fontSize: 12,
                        overflow: 'visible',
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        fontWeight: 500,
                        color: '#615D5D',
                    },
                }} />
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
};
