import { Box, Stack, Typography, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { columns } from './data/reportsDetail';
import { arrayBillingCycle, arrayStatus } from '../BillingReporting/data/arrayData';
import moment from 'moment';


export const BillingReportDetailPrint = () => {
    const navigate = useNavigate();
    const [reportData, setReportData] = useState(null);
    const [filteredArrayData, setFilteredArrayData] = useState([]);

    useEffect(() => {
        const newWindowState = JSON.parse(localStorage.getItem('printData'));
        const { invoiceData, filteredArrayData } = newWindowState

        setReportData(invoiceData)
        setFilteredArrayData(filteredArrayData)

        setTimeout(() => {
            window.print();
        }, 1000)

    }, []);

    return (
        <>
            <Stack sx={{ bgcolor: '#FFFFFF', height: '100vh', width: '100%' }}>
                <Stack pt={3}>
                    <Stack ml={"39px"} width={"100%"}>
                        <Grid container spacing={5} alignItems="flex-start" >
                            {/* Invoice Section */}
                            <Grid item xs={12} md={5.5}>
                                <Typography fontSize={15} fontWeight={500} textTransform={'uppercase'} fontFamily={'Roboto'} >
                                    Invoice
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                {reportData != null && reportData.invoice ? reportData.invoice : "-"}
                                            </Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Invoice</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={4}>
                                                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                    {reportData != null && reportData.status ? arrayStatus.find((status) => status.id == reportData.status)?.value : "-"}
                                                </Typography>
                                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: reportData != null && reportData.status ? arrayStatus.find((status) => status.id == reportData.status)?.color : "#32D9A2" }} />
                                            </Stack>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Status</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                {reportData != null && reportData.status ? arrayBillingCycle.find((cycle) => cycle.id == reportData.status)?.value : "-"}
                                            </Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Cycle</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                {reportData != null && reportData.invoiceDate ? moment(reportData.invoiceDate).format("MMMM DD, YYYY") : "-"}
                                            </Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Invoice Date</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.amount ? `$${parseFloat(reportData.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Amount</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.amountPaid ? `$${parseFloat(reportData.amountPaid).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Amount Paid</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.balanceDue ? `$${parseFloat(reportData.balanceDue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Balance Due</Typography>
                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.dueDate ? moment(reportData.dueDate).format("MMMM DD, YYYY") : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Due Date</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Customer Section */}
                            <Grid item xs={12} md={1.5}>
                                <Typography fontSize={15} fontWeight={500} textTransform={'uppercase'} fontFamily={'Roboto'} >
                                    Customer
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Stack mt={'30px'}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                {reportData != null && reportData.companyAddress && reportData.companyAddress.address1 ? reportData.companyAddress.address1 : ""}
                                            </Typography>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                {reportData != null && reportData.companyAddress && reportData.companyAddress.address2 ? reportData.companyAddress.address2 : ""}
                                            </Typography>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                {reportData != null && reportData.companyAddress && `${reportData.companyAddress.city}, ${reportData.companyAddress.state} ${reportData.companyAddress.zipCode}`}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Devices Section */}
                            <Grid item xs={12} md={5}>
                                <Typography fontSize={15} fontWeight={500} textTransform={'uppercase'} fontFamily={'Roboto'}>
                                    Devices
                                </Typography>
                                <Grid container spacing={3} >
                                    <Grid item xs={3.5}>
                                        <Stack mt={'30px'}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                                <Typography fontSize={14} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                    Active Devices
                                                </Typography>
                                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: "#32D9A2" }} />
                                            </Stack>
                                            <Typography fontSize={24} fontFamily={'Roboto'} fontWeight={500} color={'#151D26'}>
                                                {filteredArrayData.length > 0 ? filteredArrayData.filter((device) => (device.status == "1")).length : "-"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Stack mt={'30px'}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                                <Typography fontSize={14} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                    Disabled Devices
                                                </Typography>
                                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: "#FF3941" }} />
                                            </Stack>
                                            <Typography fontSize={24} fontFamily={'Roboto'} fontWeight={500} color={'#151D26'}>
                                                {filteredArrayData.length > 0 ? filteredArrayData.filter((device) => (device.status == "2")).length : "-"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>
                </Stack>
                <Stack flex={1} border={'1px solid #E1E3E8'} borderRadius={'10px 0 0 10px'} mt={'20px'} ml={'22px'} mb={'19px'}>
                    <DefaultDataGrid rows={filteredArrayData} columns={columns} onRowClick={(e) => navigate(`/billing-reports-listing/detail`, { state: { id: e.row._id } })} />
                </Stack>
            </Stack >
        </>
    );
};
