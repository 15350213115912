import { Box, Stack, Typography, Backdrop, CircularProgress, Switch, Grid, LinearProgress, Checkbox } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SearchInput } from '../../components/SearchInput/index.js';
import { AssetItem } from '../../modules/AssetMapView/AssetItem.jsx';
import { MapView } from '../../components/Map/MapView.jsx';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios';
import moment from 'moment';
import { NodeItem } from './NodeItem.jsx';
import { MapViewNode } from '../../components/Map/MapViewNode.jsx';
import TimelineTimePanel from '../../modules/NodeDetail/Timeline/TimelineTimePanel.jsx';
import { TimeLineGraph } from '../../components/Timeline/TimeLineGraph.jsx';
import { AssetMapViewFilter } from './AssetMapViewFilter.jsx';
import settingSliderIcon from '../../assets/images/settingSliderIcon.svg';

export const AssetMapView = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSiderbarOpen, setIsSiderbarOpen] = useState(localStorage.getItem("isSiderbarOpen"));
    const [isFullMap, setIsFullMap] = useState(false);
    const [arrayData, setArrayData] = useState([]);
    const [arrayNodeData, setArrayNodeData] = useState([]);
    const [query, setQuery] = useState("");
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [arraySummeryGroups, setArraySummeryGroups] = useState([]);
    const [arraySummeryGroupsNode, setArraySummeryGroupsNode] = useState([]);
    const [isAssetOrNode, setIsAssetOrNode] = useState(false);
    const [eventPercentage, setEventPercentage] = useState(-1)
    const [arrayTimeline, setArrayTimeline] = useState([]);
    const [isTimeline, setIsTimline] = useState(localStorage.getItem("mapViewTimeLineSelected") == "1");
    const [isTotals, setIsTotals] = useState(localStorage.getItem("mapViewTotalsSelected") == "1");
    const [isFilter, setIsFilter] = useState(false);
    const arrayTopAssetStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Moving", totals: 0, color: "#32D9A2" },
        { id: "2", name: "Events", totals: 0, color: "#1E75FF" },
        { id: "3", name: "Anomalies", totals: 0, color: "#FFAD2C" },
        { id: "4", name: "Idle", totals: 0, color: "#979797" },
    ]

    const arrayTopNodeStatusData = [
        { id: "0", name: "Total", totals: 0, color: "#ffffff" },
        { id: "1", name: "Active", totals: 0, color: "#32D9A2" },
        { id: "2", name: "Events", totals: 0, color: "#1E75FF" },
        { id: "3", name: "Anomalies", totals: 0, color: "#FFAD2C" },
        { id: "4", name: "Offline", totals: 0, color: "#979797" },
    ]

    useEffect(() => {
        getSummery()
        getNodeSummery()
    }, []);

    useEffect(() => {
        if (!isAssetOrNode) {
            getAssetTimelineData()
        } else {
            getTimelineData()
        }
    }, [isAssetOrNode]);

    const getSummery = async () => {
        // setIsLoading(true)
        api.get('/apis/Assets/summery').then(async (res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArraySummeryGroups(resData.arrayGroup)
                // setPercentageInSummery(resData.inServicePercentage)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getNodeSummery = async () => {
        // setIsLoading(true)
        api.get('/apis/Nodes/summery').then(async (res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArraySummeryGroupsNode(resData.arrayGroup)
            }
            // setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }
    // const applyFilters = (arrayDataAll, query, selectedStatus, selectedGroup, selectedTracker, selectedCategory, selectedType) => {
    //     if (!isAssetOrNode) {
    //         var finalArray = []
    //         var filteredArraySearch = arrayDataAll
    //             .filter((dataObj) => {
    //                 let matches = true;
    //                 const properties = ['assetName', 'serialNumber'];
    //                 let containsQuery = false;
    //                 properties.forEach((property) => {
    //                     var str = dataObj[property] + ""
    //                     if (str.toLowerCase().includes(query.toLowerCase())) {
    //                         containsQuery = true;
    //                     }
    //                 });
    //                 if (!containsQuery) {
    //                     matches = false;
    //                 }
    //                 return matches;
    //             });
    //         finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []

    //         // if (selectedStatus !== null && selectedStatus != -1) {
    //         //     var filteredArrayStatus = finalArray
    //         //         .filter((dataObj) => {
    //         //             let matches = true;
    //         //             let containsQuery = false;
    //         //             if (selectedStatus == speedStatus) {
    //         //                 containsQuery = true;
    //         //             }

    //         //             if (!containsQuery) {
    //         //                 matches = false;
    //         //             }
    //         //             return matches;
    //         //         });
    //         //     finalArray = [...filteredArrayStatus]
    //         // }

    //         // if (selectedTracker !== null && selectedTracker != -1) {
    //         //     var filteredArrayTracker = finalArray
    //         //         .filter((dataObj) => {
    //         //             let matches = true;
    //         //             let containsQuery = false;

    //         //             if (dataObj.serialNumberID === selectedTracker) {
    //         //                 containsQuery = true;
    //         //             }

    //         //             if (!containsQuery) {
    //         //                 matches = false;
    //         //             }
    //         //             return matches;
    //         //         });
    //         //     finalArray = [...filteredArrayTracker]
    //         // }

    //         // if (selectedGroup !== null && selectedGroup != -1) {
    //         //     var filteredArrayGroup = finalArray
    //         //         .filter((dataObj) => {
    //         //             let matches = true;
    //         //             let containsQuery = false;
    //         //             if (dataObj.groups != null && dataObj.groups.length > 0) {
    //         //                 if (dataObj.groups.includes(selectedGroup)) {
    //         //                     containsQuery = true;
    //         //                 }
    //         //             }
    //         //             if (!containsQuery) {
    //         //                 matches = false;
    //         //             }
    //         //             return matches;
    //         //         });
    //         //     finalArray = [...filteredArrayGroup]
    //         // }
    //         // if (selectedCategory !== null && selectedCategory != -1) {
    //         //     var fliterArrayCategory = finalArray
    //         //         .filter((dataObj) => {
    //         //             let matches = true;
    //         //             let containsQuery = false;

    //         //             if (dataObj.categoryID === selectedCategory) {
    //         //                 containsQuery = true;
    //         //             }

    //         //             if (!containsQuery) {
    //         //                 matches = false;
    //         //             }
    //         //             return matches;
    //         //         });
    //         //     finalArray = [...fliterArrayCategory]
    //         // }
    //         // if (selectedType !== null && selectedType != -1) {
    //         //     var fliterArrayType = finalArray
    //         //         .filter((dataObj) => {
    //         //             let matches = true;
    //         //             let containsQuery = false;

    //         //             if (dataObj.typeID === selectedType) {
    //         //                 containsQuery = true;
    //         //             }

    //         //             if (!containsQuery) {
    //         //                 matches = false;
    //         //             }
    //         //             return matches;
    //         //         });
    //         //     finalArray = [...fliterArrayType]
    //         // }
    //         // if (query === "" && selectedStatus === -1 && selectedGroup === -1 && selectedTracker === -1 && selectedCategory === -1 && selectedType === -1) {
    //         //     return arrayDataAll
    //         // }
    //         if (query === "") {
    //             return arrayDataAll
    //         }
    //         return finalArray
    //     } else {
    //         var finalArrayNode = []
    //         var filteredArraySearchNode = arrayDataAll
    //             .filter((dataObj) => {
    //                 let matches = true;
    //                 const properties = ['nodeName', 'sensorNumber'];
    //                 let containsQuery = false;
    //                 properties.forEach((property) => {
    //                     var str = dataObj[property] + ""
    //                     if (str.toLowerCase().includes(query.toLowerCase())) {
    //                         containsQuery = true;
    //                     }
    //                 });
    //                 if (!containsQuery) {
    //                     matches = false;
    //                 }
    //                 return matches;
    //             });
    //         finalArrayNode = filteredArraySearchNode.length > 0 ? [...filteredArraySearchNode] : []
    //         if (query === " ") {
    //             return arrayDataAll
    //         }
    //         return finalArrayNode
    //     }

    // }

    const handleQueryChange = (event) => {
        if (!isAssetOrNode) {
            setQuery(event.target.value)
        } else {
            setQuery(event.target.value)
        }
    };

    function calculateDuration(startTime, endTime) {
        console.log("startTime", startTime)
        console.log("endTime", endTime)
        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);

        console.log("days", days)
        console.log("hours", hours)
        console.log("minutes", minutes)
        console.log("seconds", seconds);


        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        return durationString.trim();
    }

    const getTimelineData = () => {
        // setIsLoading(true)
        api.post('/apis/RuleEvents/getNodeEventTimelineData/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayTimeline(resData.arrayData)

                const now = moment();
                const hours = now.hours();
                const minutes = now.minutes();
                const seconds = now.seconds();

                // Convert hours, minutes, and seconds into total seconds
                const currentSecond = (hours * 3600) + (minutes * 60) + seconds;
                const totalSecondsInDay = 24 * 60 * 60;
                const percentageWidth = (currentSecond / totalSecondsInDay) * 100;
                setEventPercentage(percentageWidth)

            }
            //setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getAssetTimelineData = () => {
        // setIsLoading(true)
        api.post('/apis/RuleEvents/getAssetEventTimelineData/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayTimeline(resData.arrayData)

                const now = moment();
                const hours = now.hours();
                const minutes = now.minutes();
                const seconds = now.seconds();

                // Convert hours, minutes, and seconds into total seconds
                const currentSecond = (hours * 3600) + (minutes * 60) + seconds;
                const totalSecondsInDay = 24 * 60 * 60;
                const percentageWidth = (currentSecond / totalSecondsInDay) * 100;
                setEventPercentage(percentageWidth)

            }
            //setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <Stack height={"100vh"} width="100%" overflow={"hidden"}>
            <Stack flex={1} borderTop={'1px solid #E1E3E8'} direction={'row'} height={"100%"} width="100%" overflow={"hidden"}>
                <Box
                    width={"300px"}
                    flexShrink={0}
                    borderRight={'1px solid #E1E3E8'}
                    display={"auto"}
                    height={"100vh"}
                    sx={{
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                            width: '4px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            width: '4px',
                            backgroundColor: '#bdbdbd',
                        },
                    }}
                >
                    <Stack ml={'10px'} mr={'10px'} mt={'16px'} direction={'row'} gap={'10px'}>
                        <SearchInput
                            name={'search'}
                            handleQueryChange={handleQueryChange}
                            sx={{ minWidth: '200px' }} />
                        <Box
                            sx={{
                                border: '1px solid #E2E2EA',
                                bgcolor: '#F6F8FA',
                                borderRadius: '5px',
                                height: '30px',
                                width: "30px",
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            onClick={(e) => {
                                setIsFilter(!isFilter)
                            }}
                        >
                            <Box component={'img'} src={settingSliderIcon} sx={{ width: '20px', height: '16px' }}></Box>
                        </Box>
                    </Stack>
                    <Stack direction={'row'} ml={'16px'} mt={'4px'} alignItems={'center'}>
                        <Typography fontWeight={400} fontSize={12} color={"#615D5D"} fontFamily={'Roboto'}> Assets </Typography>
                        <Switch
                            sx={{
                                alignItems: 'center',
                                ml: '5px',
                                mr: '5px',
                                '.MuiSwitch-thumb': {
                                    bgcolor: '#1E75FF',
                                    width: '20px',
                                    height: '20px',
                                },
                                '.Mui-checked .MuiSwitch-thumb': {
                                    backgroundColor: '#151D26',
                                },
                                '& .Mui-checked+.MuiSwitch-track, .MuiSwitch-track': {
                                    backgroundColor: '#EAEBEA !important',
                                    boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 16%)',
                                    height: '20px',
                                    width: '40px',
                                    borderRadius: '10px',
                                },
                                '.MuiSwitch-switchBase': {
                                    top: '0px',
                                },
                            }}
                            checked={isAssetOrNode}
                            onChange={(e) => { setIsAssetOrNode(e.target.checked) }}
                            name={"isAssetOrNode"}
                        />
                        <Typography fontWeight={400} fontSize={12} color={"#615D5D"} fontFamily={'Roboto'}> Nodes </Typography>
                    </Stack>
                    <AssetMapViewFilter
                        arrayAssetData={arrayData}
                        query={query}
                        isAssetOrNode={isAssetOrNode}
                        setFilteredArrayData={setFilteredArrayData}
                        setArrayData={setArrayData}
                        setArrayNodeData={setArrayNodeData}
                        arrayNodeData={arrayNodeData}
                        isFilter={isFilter}
                    />
                    {isFilter && <Stack direction={'row'} ml={'16px'} mt={'14px'}>
                        <Typography fontWeight={400} fontFamily={'Roboto'} fontSize={12} color={'#615D5D'}> Timeline</Typography>

                        <Checkbox
                            checked={isTimeline}
                            sx={{ width: '16px', height: '16px', ml: "15px" }}
                            onChange={(e) => {
                                setIsTimline(e.target.checked)
                                localStorage.setItem("mapViewTimeLineSelected", e.target.checked ? "1" : "0")
                            }}
                        />

                        <Typography fontWeight={400} fontFamily={'Roboto'} fontSize={12} ml={'17px'} color={'#615D5D'}> Totals</Typography>

                        <Checkbox
                            checked={isTotals}
                            sx={{ width: '16px', height: '16px', ml: "16px" }}
                            onChange={(e) => {
                                setIsTotals(e.target.checked)
                                localStorage.setItem("mapViewTotalsSelected", e.target.checked ? "1" : "0")
                            }}
                        />
                    </Stack>}
                    <Box sx={{ height: '1px', bgcolor: "#8F98AB45", mt: '20px', mb: '11px' }} ></Box>
                    {isAssetOrNode == false ? filteredArrayData.length > 0 ? filteredArrayData.map((item, index) => {
                        var speedStatus = item.tracker != null && item.tracker.state != null && item.tracker.state == 1 ? "Moving" : "Idle"
                        return <AssetItem
                            key={`asset-item-${index}`}
                            id={item._id}
                            title={item.assetName}
                            temp={item.tracker != null && item.tracker.temperature != null ? item.tracker.temperature : ""}
                            speed={item.tracker != null && item.tracker.positionSpeed != null ? item.tracker.positionSpeed : ""}
                            tags={[
                                {
                                    text: speedStatus,
                                    textSize: 12,
                                    borderColor: speedStatus == "Moving" ? 'green.main' : "#E1E3E8",
                                },
                            ]}
                            deviceID={item.tracker != null && item.tracker.deviceSerialNumber != null ? item.tracker.deviceSerialNumber : ""}
                            date={item.tracker != null && item.tracker.timestamp != null ? item.tracker.timestamp : ""}
                        />
                    }) : <Stack
                        justifyContent="center"
                        alignItems="center"
                        height="calc(100vh - 150px)"
                    >
                        <Typography>No Available Data</Typography>
                    </Stack> : filteredArrayData.length > 0 ? filteredArrayData.map((item, index) => {
                        return <NodeItem
                            key={`node-item-${index}`}
                            id={item._id}
                            title={item.nodeName}
                            temp={item.sensor != null && item.sensor.temperature != null ? item.sensor.temperature : ""}
                            speed={item.sensor != null && item.sensor.positionSpeed != null ? item.sensor.positionSpeed : ""}
                            deviceID={item.sensor != null && item.sensor.sensorNumber != null ? item.sensor.sensorNumber : ""}
                            battery={item.sensor != null && item.sensor.battery != null ? item.sensor.battery : ""}
                            isEvent={item.isEvent}
                            date={item.sensor != null && item.sensor.timestamp != null ? item.sensor.timestamp : ""}
                        />
                    }) : <Stack
                        justifyContent="center"
                        alignItems="center"
                        height="calc(100vh - 150px)"
                    >
                        <Typography>No Available Data</Typography>
                    </Stack>}
                </Box>
                <Stack flex={1}>
                    {isTotals == "1" && (!isAssetOrNode ?
                        (<Stack>
                            <Stack direction={'row'} alignItems={'center'} gap={5} ml={'25px'} mt={'19px'}>
                                <Stack>
                                    <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={'15px'} >
                                        ASSETS
                                    </Typography>
                                    <Stack direction={'row'} alignItems={'center'} gap={5}>
                                        {arrayTopAssetStatusData.map((status) => (
                                            <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                                <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                                    {status.name}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        width: 8,
                                                        height: 8,
                                                        borderRadius: '50%',
                                                        backgroundColor: status.color,
                                                        mt: '16px'
                                                    }}
                                                />
                                                <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>

                                                    {status.id == "0"
                                                        ? arrayData.length
                                                        : status.id == "1"
                                                            ? arrayData.filter((data) => data && data.tracker && data.tracker.positionSpeed > 0).length
                                                            : status.id == "2"
                                                                ? arrayData.filter((data) => data && data.isEvent == "1").length
                                                                : status.id == "4"
                                                                    ? arrayData.filter((data) => data && data.tracker && (data.tracker.positionSpeed === "0.0" || data.tracker.positionSpeed === "-")).length
                                                                    : status.totals
                                                    }
                                                </Typography>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                                <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                                <Stack width={"100%"}>
                                    <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                        {arraySummeryGroups.length > 0 ? "TOP METRICS" : ""}
                                    </Typography>
                                    {arraySummeryGroups.length > 0 ?
                                        <Grid container xs={12} columnSpacing={"26px"}>
                                            {arraySummeryGroups.map((obj) => {
                                                return (
                                                    <Grid item xs={2.4} key={obj._id}>
                                                        <Stack>
                                                            <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                                {obj.name + " " + `${obj.percentage}%`}
                                                            </Typography>
                                                            <LinearProgress
                                                                sx={{
                                                                    width: '100%',
                                                                    height: "6px",
                                                                    flexShrink: 0,
                                                                    borderRadius: '2px',
                                                                    bgcolor: '#EFEFEF',
                                                                    mt: "17px",
                                                                    '.MuiLinearProgress-bar': {
                                                                        borderRadius: '3px',
                                                                        bgcolor: obj.color ? obj.color : "",
                                                                    },
                                                                }}
                                                                variant={'determinate'}
                                                                value={obj.percentage ? obj.percentage : 0}
                                                            />
                                                            <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                                {obj.assignedAsset}
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        : <Stack height="70px" />}
                                </Stack>
                            </Stack> < Box sx={{ border: '1px solid #E1E3E8' }}></Box>
                        </Stack>)
                        :
                        (<Stack>
                            <Stack direction={'row'} alignItems={'center'} gap={5} ml={'25px'} mt={'19px'}>
                                <Stack>
                                    <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                        NODES
                                    </Typography>
                                    <Stack direction={'row'} alignItems={'center'} gap={5} >
                                        {arrayTopNodeStatusData.map((status) => (
                                            <Stack alignItems={'center'} key={status.id} mr={status.id == "0" ? 3 : 0}>
                                                <Typography fontWeight={400} fontSize={12} color={"#A0A3A6"} mt={'25px'} >
                                                    {status.name}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        width: 8,
                                                        height: 8,
                                                        borderRadius: '50%',
                                                        backgroundColor: status.color,
                                                        mt: '16px'
                                                    }}
                                                />
                                                <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'11px'}>
                                                    {status.id == "0" ? arrayNodeData.length : status.id == "1" ? arrayNodeData.filter((data) => data.sensor.state == "1").length : status.id == "2" ? arrayNodeData.filter((data) => data.isEvent == "1").length : status.id == "4" ? arrayNodeData.filter((data) => data.sensor.state == "0").length : status.totals}
                                                </Typography>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                                <Box height={100} width={'1px'} bgcolor={'#E1E3E8'} flexShrink={0} />
                                <Stack width={"100%"}>
                                    <Typography fontWeight={500} letterSpacing={1.7} textTransform={'uppercase'} fontSize={15}>
                                        {arraySummeryGroupsNode.length > 0 ? "TOP METRICS" : ""}
                                    </Typography>
                                    {arraySummeryGroupsNode.length > 0 ?
                                        <Grid container xs={12} columnSpacing={"26px"}>
                                            {arraySummeryGroupsNode.map((obj) => {
                                                return (
                                                    <Grid item xs={2.4} key={obj._id}>
                                                        <Stack>
                                                            <Typography fontSize={12} color={'#A0A3A6'} fontWeight={400} fontFamily={'Roboto'} mt={'25px'}>
                                                                {obj.name + " " + `${obj.percentage}%`}
                                                            </Typography>
                                                            <LinearProgress
                                                                sx={{
                                                                    width: '100%',
                                                                    height: "6px",
                                                                    flexShrink: 0,
                                                                    borderRadius: '2px',
                                                                    bgcolor: '#EFEFEF',
                                                                    mt: "17px",
                                                                    '.MuiLinearProgress-bar': {
                                                                        borderRadius: '3px',
                                                                        bgcolor: obj.color ? obj.color : "",
                                                                    },
                                                                }}
                                                                variant={'determinate'}
                                                                value={obj.percentage ? obj.percentage : 0}
                                                            />
                                                            <Typography fontSize={24} color={'#151D26'} fontWeight={500} fontFamily={'Roboto'} mt={'12px'}>
                                                                {obj.assignedNode}
                                                            </Typography>
                                                        </Stack>
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        : <Stack height="70px" />}
                                </Stack>
                            </Stack>
                            < Box sx={{ border: '1px solid #E1E3E8' }}></Box>
                        </Stack>)
                    )}
                    {!isAssetOrNode ?
                        (<MapView assetData={filteredArrayData} isFullMap={isFullMap} isTimeLine={isTimeline} />) :
                        (<MapViewNode nodeData={filteredArrayData} isFullMap={isFullMap} isTimeLine={isTimeline} />)
                    }
                    {isTimeline == "1" &&
                        <Box
                            key="timePannel"
                            sx={{
                                bgcolor: '#FFFFFF',
                                borderRadius: '3.5px',
                                border: '1px solid #E6E7E8',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {arrayTimeline.length > 0 && <TimeLineGraph arrayData={arrayTimeline} totalPercentage={eventPercentage} sx={{ height: '32px', marginBottom: '8px' }} />}
                            <Box width={'100%'} height={'2px'} bgcolor={"#E6E7E8"} />
                            <TimelineTimePanel />
                        </Box>
                    }
                </Stack>
            </Stack>
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    );
};
