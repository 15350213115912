import { Box, ButtonBase, Stack, Typography, Backdrop, CircularProgress, Grid, Breadcrumbs } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios';
import { SearchInput } from '../../components/SearchInput/index.js';
import { DefaultDataGrid } from '../../ui/DefaultDataGrid/index.js';
import { columns } from './data/reportsDetail';
import { arrayBillingCycle, arrayStatus } from '../BillingReporting/data/arrayData';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { HeaderMenu } from '../../components/HeaderMenu';


export const BillingReportDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state != null && location.state.id != null ? location.state.id : ""
    const [isLoading, setIsLoading] = useState(false);
    const [reportData, setReportData] = useState(null);
    const [arrayDevice, setArrayDevice] = useState([]);
    const [filteredArrayData, setFilteredArrayData] = useState([]);
    const [query, setQuery] = useState("");
    const [arrayAssetTrackerType, setArrayAssetTrackerType] = useState([]);


    useEffect(() => {
        getDeviceDetail()
        if (selectedDataId != "") {
            getData()
        }
    }, []);

    const getData = () => {
        setIsLoading(true)
        api.get('/apis/Invoices/byId/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                setReportData(data)
                setArrayDevice(data.devices)
                var filterArray = applyFilters(data.devices, query)
                setFilteredArrayData(filterArray)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const handleQueryChange = (event) => {
        setQuery(event.target.value)
        var filterArray = applyFilters(arrayDevice, event.target.value)
        setFilteredArrayData(filterArray)
    };

    const applyFilters = (arrayDataAll, query) => {
        var finalArray = []
        var filteredArraySearch = arrayDataAll
            .filter((dataObj) => {
                let matches = true;
                const properties = ['deviceSerialNumber'];
                let containsQuery = false;
                properties.forEach((property) => {
                    var str = dataObj[property] + ""
                    if (str.toLowerCase().includes(query.toLowerCase())) {
                        containsQuery = true;
                    }
                });
                if (!containsQuery) {
                    matches = false;
                }
                return matches;
            });
        finalArray = filteredArraySearch.length > 0 ? [...filteredArraySearch] : []
        if (query === "") {
            return arrayDataAll
        }
        return finalArray
    }

    const getDeviceDetail = () => {
        setIsLoading(true)
        api.get('/apis/Rules/getDetail/').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayAssetTrackerType(resData.arrayTrackerType)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    return (
        <>
            <Stack sx={{ bgcolor: '#FFFFFF', height: '100vh', width: '100%' }}>
                <Stack width={'100%'} alignItems={'flex-end'} mt={'14px'} pr={'27px'}>
                </Stack>
                <Stack pt={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} px={3}>
                        <Box>
                            <Stack
                                display={'inline-flex'}
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    svg: {
                                        fill: '#A0A3A6',
                                    },
                                }}
                                gap={0.5}
                            >
                                <ArrowBackIos
                                    sx={{
                                        fontSize: "small",
                                    }}
                                />
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={3}
                                >
                                    <Grid item>
                                        <Breadcrumbs
                                            aria-label="breadcrumb"
                                            separator={<Typography fontSize={15}
                                                fontWeight={400}
                                                fontFamily={'Roboto'}
                                                color="#A0A3A6">/</Typography>}
                                        >
                                            <Link
                                                component={RouterLink}
                                                to="/billing-reports-listing"
                                            >
                                                <Typography
                                                    fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6"
                                                >
                                                    Billing Reports
                                                </Typography>
                                            </Link>

                                            <Typography
                                                fontSize={15}
                                                fontWeight={400}
                                                fontFamily={'Roboto'}
                                                color="#A0A3A6"
                                            >
                                                Billing Report Details
                                            </Typography>
                                            <Typography fontSize={15} fontWeight={500} color={'#151D26'}>
                                                {reportData != null && reportData.invoice ? reportData.invoice : "-"}
                                            </Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                    </Stack>

                    <Stack ml={"39px"} width={"100%"} mt={'38px'}>
                        <Grid container spacing={5} alignItems="flex-start" >
                            {/* Invoice Section */}
                            <Grid item xs={12} md={5.5}>
                                <Typography fontSize={15} fontWeight={500} textTransform={'uppercase'} fontFamily={'Roboto'} >
                                    Invoice
                                </Typography>
                                <Grid container spacing={3}>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                {reportData != null && reportData.invoice ? reportData.invoice : "-"}
                                            </Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Invoice</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={4}>
                                                <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                    {reportData != null && reportData.status ? arrayStatus.find((status) => status.id == reportData.status)?.value : "-"}
                                                </Typography>
                                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: reportData != null && reportData.status ? arrayStatus.find((status) => status.id == reportData.status)?.color : "#32D9A2" }} />
                                            </Stack>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Status</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                {reportData != null && reportData.status ? arrayBillingCycle.find((cycle) => cycle.id == reportData.status)?.value : "-"}
                                            </Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Cycle</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Stack mt={"30px"}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                                {reportData != null && reportData.invoiceDate ? moment(reportData.invoiceDate).format("MMMM DD, YYYY") : "-"}
                                            </Typography>
                                            <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Invoice Date</Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.amount ? `$${parseFloat(reportData.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Amount</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.amountPaid ? `$${parseFloat(reportData.amountPaid).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Amount Paid</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.balanceDue ? `$${parseFloat(reportData.balanceDue).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Balance Due</Typography>
                                    </Grid>
                                    <Grid item xs={3}>

                                        <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#262B40'}>
                                            {reportData != null && reportData.dueDate ? moment(reportData.dueDate).format("MMMM DD, YYYY") : "-"}
                                        </Typography>
                                        <Typography fontSize={12} color={'#A0A3A6'} fontFamily={'Roboto'} fontWeight={400}>Due Date</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Customer Section */}
                            <Grid item xs={12} md={1.5}>
                                <Typography fontSize={15} fontWeight={500} textTransform={'uppercase'} fontFamily={'Roboto'} >
                                    Customer
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Stack mt={'30px'}>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                {reportData != null && reportData.companyAddress && reportData.companyAddress.address1 ? reportData.companyAddress.address1 : ""}
                                            </Typography>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                {reportData != null && reportData.companyAddress && reportData.companyAddress.address2 ? reportData.companyAddress.address2 : ""}
                                            </Typography>
                                            <Typography fontSize={15} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                {reportData != null && reportData.companyAddress && `${reportData.companyAddress.city}, ${reportData.companyAddress.state} ${reportData.companyAddress.zipCode}`}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Devices Section */}
                            <Grid item xs={12} md={5}>
                                <Typography fontSize={15} fontWeight={500} textTransform={'uppercase'} fontFamily={'Roboto'}>
                                    Devices
                                </Typography>
                                <Grid container spacing={3} >
                                    <Grid item xs={3.5}>
                                        <Stack mt={'30px'}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                                <Typography fontSize={14} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                    Active Devices
                                                </Typography>
                                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: "#32D9A2" }} />
                                            </Stack>
                                            <Typography fontSize={24} fontFamily={'Roboto'} fontWeight={500} color={'#151D26'}>
                                                {arrayDevice.length > 0 ? arrayDevice.filter((device) => (device.status == "1")).length : "-"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Stack mt={'30px'}>
                                            <Stack direction={'row'} alignItems={'center'} spacing={3}>
                                                <Typography fontSize={14} fontFamily={'Roboto'} fontWeight={400} color={'#151D26'}>
                                                    Disabled Devices
                                                </Typography>
                                                <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: "#FF3941" }} />
                                            </Stack>
                                            <Typography fontSize={24} fontFamily={'Roboto'} fontWeight={500} color={'#151D26'}>
                                                {arrayDevice.length > 0 ? arrayDevice.filter((device) => (device.status == "2")).length : "-"}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Stack>

                    <Stack direction={'row'} gap={1} mt={'22px'} ml={'22px'}>
                        <SearchInput name={'search'} handleQueryChange={handleQueryChange} sx={{ minWidth: '200px' }} />
                        <ButtonBase
                            sx={{
                                border: '1px solid #E2E2EA',
                                bgcolor: '#FFFFFF',
                                borderRadius: '5px',
                                height: '30px',
                                mr: '10px',
                                width: '110px'
                            }}
                            onClick={(e) => {
                                localStorage.setItem('printData', JSON.stringify({
                                    invoiceData: reportData,
                                    filteredArrayData: filteredArrayData
                                }));
                                const newWindow = window.open('/billing-reports-listing/detail/print', '_blank');
                            }}
                        >
                            <Typography fontSize={13} fontWeight={400} color={"#151D26"} fontFamily={'Roboto'}>Print PDF</Typography>
                        </ButtonBase>
                    </Stack>

                </Stack>
                <Stack flex={1} border={'1px solid #E1E3E8'} borderRadius={'10px 0 0 10px'} mt={'20px'} ml={'22px'} mb={'19px'}>
                    <DefaultDataGrid rows={filteredArrayData} columns={columns} onRowClick={(e) => navigate(`/billing-reports-listing/detail`, { state: { id: e.row._id } })} />
                </Stack>
            </Stack >
            <Backdrop style={{ zIndex: 1 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
