import { Box, Stack, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Tag } from '../../ui/Tag/index.js';
import { useNavigate } from 'react-router-dom';
import { SearchInput } from '../../components/SearchInput/index.js';
import moment from 'moment';


export const NodeItem = ({ id, title, temp, speed, deviceID, battery, isEvent, date }) => {
    const navigate = useNavigate();

    function calculateDuration(startTime, endTime) {

        const start = new Date(startTime);
        const end = new Date(endTime);

        // Calculate the duration in milliseconds
        const durationMs = end - start;

        // Convert durationMs to days, hours, minutes, and seconds
        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);




        // Build the duration string
        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        return durationString.trim();
    }
    return (
        <Box sx={{ position: 'relative' }}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                gap={3}
                alignItems={'center'}
                py={1.5}
                px={2.5}
                borderBottom={'2px dashed #E1E3E8'}
                sx={{
                    cursor: 'pointer',
                }}
                onClick={() => navigate(`/node-listing/detail`, { state: { id: id, isFromMap: true } })}
            >
                <Stack gap={1}>
                    <Typography fontWeight={500} fontSize={14} color={"#151D26"} whiteSpace={'nowrap'} fontFamily={'Roboto'}>
                        {title}
                    </Typography>
                    <Stack direction={'row'} alignItems={'center'} gap={2}>
                        <Box
                            sx={{
                                border: '1px solid #E1E3E8',
                                height: '20px',
                                borderRadius: '5px',
                                bgcolor: '#36B368',
                                alignContent: 'center',
                                width: '60px'
                            }}
                        >
                            <Typography whiteSpace={'nowrap'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#FFFFFF'} textAlign={'center'}>
                                {temp != null && temp != "" && temp != "-" ? (parseFloat(temp).toFixed(1) + " °F") : "-"}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                height: '20px',
                                borderRadius: '5px',
                                bgcolor: '#F6F7FA',
                                width: '65px',
                                alignContent: 'center'
                            }}
                        >
                            <Typography whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#151D26'} textAlign={'center'}>
                                {battery != null && battery != "" && battery != "-" ? `Batt.${battery}%` : "-"}
                            </Typography>
                        </Box>
                        {isEvent == "1" && <Box
                            sx={{
                                height: '20px',
                                borderRadius: '5px',
                                bgcolor: '#F6F7FA',
                                width: '60px',
                                alignContent: 'center'
                            }}
                        >
                            <Stack direction={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'} >
                                <Typography whiteSpace={'nowrap'} textOverflow={'ellipsis'} overflow={'hidden'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#151D26'} textAlign={'center'} sx={{ pl: '4px' }}>
                                    {/* {battery != null && battery != "" && battery != "-" ? `Batt.${battery}%` : "-"} */}
                                    Events
                                </Typography>
                                <Box
                                    sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        backgroundColor: "#FF3941",

                                    }}
                                    mr={'5px'}
                                />
                            </Stack>
                        </Box>}

                        <Typography whiteSpace={'nowrap'} fontSize={10} fontWeight={400} fontFamily={'Roboto'} color={'#151D26'}>
                            {date != "" ? calculateDuration(date, moment().format("MM/DD/YY hh:mm a")) : "-"}
                        </Typography>

                    </Stack>
                </Stack>
                <Box sx={{ position: "absolute", top: 20, bottom: 20, right: "15px" }}>
                    <ExpandMore
                        sx={{
                            transform: 'rotate(-90deg) translateY(5px)',
                            flexShrink: 0,
                        }}
                    />
                </Box>
            </Stack>
        </Box>
    );
};
