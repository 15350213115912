import { Box, ButtonBase, Stack, Typography, Backdrop, CircularProgress, Checkbox, FormControlLabel, Switch, Breadcrumbs, Grid, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowBackIos } from '@mui/icons-material';
import { Tag } from '../../ui/Tag/index.js';
import { Groups } from '../../modules/Asset/Groups.jsx';
import { tabs as initialTabs } from './data/tabs.js';
import { Tabs } from '../../ui/Tabs/index.js';
import { Events } from './Events/Events.jsx';
import { FormikProvider, useFormik } from 'formik';
import { HeaderMenu } from '../../components/HeaderMenu.jsx';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios';
import { LocationsMap } from './LocationsMap/LocationsMap.jsx';
import { Details } from './Details/Details.jsx';
import { Charts } from './Charts/Charts.jsx';
import { NodeData } from './NodeData/NodeData.jsx';
import { SecondaryData } from './SecondaryData/SecondaryData.jsx';
import { arrayProduct } from '../NodeNew/data/arrayData.jsx';
import { MachineLearning } from './Machine Learning/MachineLearning.jsx';
import { PlusIcon } from '../../assets/icons/PlusIcon.jsx';
import { Add } from '@mui/icons-material';
import { SmartVibrationOverview } from './SmartVibrationOverview/SmartVibrationOverview.jsx';
import { TimeLine } from './Timeline/Timeline.jsx';
import { Link as RouterLink } from 'react-router-dom';
import MapIcon from '../../assets/icons/map.svg'

export const NodeDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [tabs, setTabs] = useState(initialTabs);
    const selectedDataId = location.state != null && location.state.id != null ? location.state.id : ""
    const isFromMaps = location.state != null && location.state.isFromMap != null ? location.state.isFromMap : false
    const [isLoading, setIsLoading] = useState(false);
    const [arrayGroup, setArrayGroup] = useState([]);
    const [nodeData, setAssetData] = useState(null);
    const [sensorData, setSensorData] = useState(null);
    const [activeTab, setActiveTab] = useState(location.state != null && location.state.activeTab != null ? location.state.activeTab : tabs[0].value);
    const [modelSetupOpen, setModelSetupOpen] = useState(false)
    const [isAutoRefresh, setIsAutoRefresh] = useState(false);
    const [showMap, setShowMap] = useState(false);

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };


    useEffect(() => {
        getGroupData()
        if (selectedDataId != "") {
            getData()
        }
    }, []);

    function getGroupData() {
        // setIsLoading(true)
        api.get('/apis/Groups/activeNode').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayGroup(resData)
                // setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = () => {
        setIsLoading(true)
        api.get('/apis/Nodes/byId/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                var sensorData = data.sensor
                setAssetData(data)
                setSensorData(sensorData)
                var showSecondaryData = sensorData != null && sensorData.product == "1" || null
                // updateTabs(showSecondaryData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }


    const updateTabs = (showSecondaryData) => {
        if (showSecondaryData) {
            setTabs((prevTabs) =>
                prevTabs.some((tab) => tab.value === 'secondaryData')
                    ? prevTabs
                    : [...prevTabs, { title: 'Secondary Data', value: 'secondaryData' }]
            );
        } else {
            setTabs((prevTabs) =>
                prevTabs.filter((tab) => tab.value !== 'secondaryData')
            );
        }
    };

    const apiCalling = async (groups) => {
        setIsLoading(true)
        var data = {
            groups: groups,
            sensorNumberID: nodeData.sensorNumberID,
            sensorNumber: nodeData.sensorNumber
        }
        console.log("data", data);
        api.put('/apis/Nodes/' + selectedDataId, data).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                getData()
            }
        }).catch(function (error) {
            console.log(error);
        });

    }

    function onCheck(nodeData, isFavourite) {
        setIsLoading(true)
        var data = {
            sensorNumberID: nodeData.sensorNumberID,
            sensorNumber: nodeData.sensorNumber,
            isFavourite: isFavourite
        }
        api.put('/apis/Nodes/' + nodeData._id, data).then((res) => {

            getData()
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onUpdateNodeData = (nodeData) => {
        setAssetData(nodeData)
    }


    const onMapClose = () => {
        setShowMap(false)
    }

    return (
        <Stack width={'100%'}>
            <Stack sx={{ bgcolor: '#FFFFFF', height: 'calc(100vh - 50px)', width: '100%' }}> 
                <Stack pt={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} px={3}>
                        <Box
                        // onClick={() => navigate(-1)}
                        >
                            <Stack
                                display={'inline-flex'}
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    svg: {
                                        fill: '#A0A3A6',
                                    },
                                }}
                                gap={0.5}
                            >
                                <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={3}
                                >
                                    <Grid item>
                                        <Stack direction="row" alignItems="center" gap={0.5} >
                                            <ArrowBackIos
                                                onClick={() => {
                                                    navigate(-1);
                                                }}
                                                sx={{
                                                    fontSize: "small",
                                                }}
                                            />
                                            <Breadcrumbs
                                                aria-label="breadcrumb"
                                                separator={<Typography fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6">/</Typography>}
                                            >

                                                {isFromMaps ?
                                                    <Typography
                                                        fontSize={15}
                                                        fontWeight={400}
                                                        fontFamily={'Roboto'}
                                                        color="#A0A3A6"
                                                    >
                                                        Maps
                                                    </Typography>
                                                    :
                                                    <Typography
                                                        fontSize={15}
                                                        fontWeight={400}
                                                        fontFamily={'Roboto'}
                                                        color="#A0A3A6"
                                                    >
                                                        Nodes
                                                    </Typography>
                                                }

                                                {nodeData != null && nodeData.nodeCategory && <Typography
                                                    fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6"
                                                >
                                                    {nodeData != null && nodeData.nodeCategory ? nodeData.nodeCategory : "-"}
                                                </Typography>}
                                                {nodeData != null && nodeData.nodeType && <Typography
                                                    fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6"
                                                >
                                                    {nodeData != null && nodeData.nodeType ? nodeData.nodeType : "-"}
                                                </Typography>}
                                                <Typography fontSize={15} fontWeight={500} color={'#151D26'}>
                                                    {nodeData != null && nodeData.nodeName ? nodeData.nodeName : "-"}
                                                </Typography>
                                            </Breadcrumbs>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                    </Stack>

                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} px={3} mt={'15px'}>
                        <Stack direction={'row'} alignItems={'center'} gap={6}>
                            <Typography fontSize={20} fontWeight={500}>
                                {nodeData?.nodeName || "-"}
                            </Typography>
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: sensorData != null && sensorData.state == "1" ? "green.main" : "red.main",
                                }}
                            />
                        </Stack>
                        {/* <Stack direction={'row'} gap={1}>
                                {nodeData?.sensorNumber && <Tag text={nodeData?.sensorNumber} borderColor={'green.main'} bgcolor={"#EFF3F7"} />}
                            </Stack> */}
                        <Stack direction={'row'}>
                            {activeTab === 'overview' && nodeData != null && sensorData != null && sensorData.product != null && sensorData.product != "4" && <Box
                                sx={{
                                    mr: '10px',
                                    height: '30px',
                                    width: '43px',
                                    backgroundColor: '#F6F8FA',
                                    borderRadius: "5px",
                                    border: '1px solid #E2E2EA',
                                    cursor: 'pointer',
                                }}
                                px="10px"
                                py="5px"
                                component={'img'}
                                onClick={() => {
                                    setShowMap(true)
                                }}
                                src={MapIcon}
                            />}
                            {activeTab === 'ml' && modelSetupOpen === false && <ButtonBase
                                sx={{
                                    pl: '13px',
                                    pr: '34px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    bgcolor: 'secondary.main',
                                    borderRadius: '5px',
                                    height: '30px',
                                    mr: '10px'
                                }}
                                onClick={() => {
                                    setModelSetupOpen(true)
                                }}
                            >
                                <PlusIcon />
                                <Typography fontSize={13} fontWeight={'Medium'} color={"#FAFAFB"} fontFamily={'Roboto'}>
                                    MODEL
                                </Typography>
                            </ButtonBase>}
                            <ButtonBase
                                sx={{
                                    px: '40px',
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#F6F8FA',
                                    borderRadius: '5px',
                                    height: '30px',
                                    mr: '10px'
                                }}
                                onClick={() => {
                                    navigate(`/node-listing/manage`, { state: { id: selectedDataId } })
                                }}
                            >
                                <Typography fontSize={12} fontWeight={500} color={"#151D26"} fontFamily={'Roboto'}>Edit</Typography>
                            </ButtonBase>
                            <Groups label="Groups">
                                {arrayGroup.length > 0 && <FormControlLabel control={<Checkbox
                                    checked={nodeData?.groups != null ? arrayGroup.length == nodeData?.groups.length : false}
                                    onChange={(e) => {
                                        var groups = nodeData?.groups ? nodeData?.groups : []
                                        if (arrayGroup.length == groups.length) {
                                            groups = []
                                        } else {
                                            groups = arrayGroup.map(data => data._id)
                                        }
                                        apiCalling(groups)
                                    }} />}
                                    label='All Groups' />}
                                {arrayGroup.map((item, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={<Checkbox
                                            checked={nodeData?.groups != null ? nodeData?.groups.includes(item._id) : false}
                                            onChange={(e) => {
                                                var groups = nodeData?.groups ? nodeData?.groups : []
                                                if (groups.includes(item._id)) {
                                                    groups = groups.filter(obj => obj != item._id)
                                                } else {
                                                    groups.push(item._id)
                                                }
                                                apiCalling(groups)
                                            }}
                                        />}
                                        label={item.name} />
                                ))}
                            </Groups>
                        </Stack>
                    </Stack>

                    <Stack mt={'22px'}>
                        <Stack width={'100%'}>
                            <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} activeColor={'#051219'} activeFontMedium={500} />
                        </Stack>
                    </Stack>

                    {activeTab === 'overview' && <Stack direction={'row'} px={3} mt={'15px'} justifyContent={'space-between'} alignItems={'center'}>
                        <Stack direction={'row'}>
                            <Stack gap={1.5} direction={'row'}>
                                {nodeData?.isFavourite != null && nodeData?.isFavourite == "1" && <Box
                                    sx={{
                                        p: "2px",
                                        borderRadius: '20px',
                                        bgcolor: "#27B2704F",
                                        height: '30px',
                                        alignContent: 'center',
                                    }}
                                >
                                    <Typography
                                        height="100%"
                                        width="100%"
                                        px="22px"
                                        borderRadius='20px'
                                        backgroundColor="#27B270"
                                        color={'#FFFFFF'}
                                        fontFamily={'Roboto'}
                                        fontWeight={400}
                                        fontSize={12}
                                        alignContent="center"
                                    >
                                        Watching
                                    </Typography>
                                </Box>}
                                {nodeData?.groupData != null && nodeData?.groupData.map(data => {
                                    return (
                                        <Box
                                            key={data._id}
                                            sx={{
                                                px: 2,
                                                border: '1px solid #E2E2EA',
                                                borderRadius: '20px',
                                                bgcolor: "#F7F7F7",
                                                height: '30px',
                                            }}
                                        >
                                            <Stack
                                                direction={'row'}
                                                justifyContent={'space-between'}
                                                alignItems={'center'}
                                                gap={2}
                                                height="100%"
                                            >
                                                <Typography
                                                    color={'#44444F'}
                                                    fontFamily={'Roboto'}
                                                    fontWeight={400}
                                                    fontSize={12}
                                                >
                                                    {data.name}
                                                </Typography>
                                                <Box
                                                    sx={{
                                                        width: 8,
                                                        height: 8,
                                                        borderRadius: '50%',
                                                        backgroundColor: data.color,
                                                    }}
                                                />
                                            </Stack>
                                        </Box>
                                    )
                                })}
                            </Stack>
                        </Stack>
                        {/* 
                        {
                            nodeData != null && (sensorData != null && sensorData.product != null && sensorData.product === "4" ? <Stack>
                                <Stack direction={'row'} alignItems={'center'}>
                                    <Typography fontSize={12} fontFamily={"Roboto"} fontWeight='medium' sx={{ pr: '10px' }}>
                                        Auto Refresh
                                    </Typography>
                                    <Switch
                                        sx={{
                                            alignItems: 'center',
                                            ml: -1,
                                            '.MuiSwitch-thumb': {
                                                bgcolor: 'primary.main',
                                                width: 20,
                                                height: 20,
                                            },
                                            '.Mui-checked .MuiSwitch-thumb': {
                                                backgroundColor: 'green.main',
                                            },
                                            '& .Mui-checked+.MuiSwitch-track, .MuiSwitch-track': {
                                                backgroundColor: '#EAEBEA !important',
                                                boxShadow: 'inset 0 3px 6px 0 rgba(0 0 0 / 16%)',
                                                height: 20,
                                                width: 40,
                                                borderRadius: '10px',
                                            },
                                            '.MuiSwitch-switchBase': {
                                                top: '0px',
                                            },
                                        }}
                                        checked={isAutoRefresh}
                                        onChange={(e) => { setIsAutoRefresh(e.target.checked) }}
                                        name={"isAutoRefresh"}
                                    />
                                </Stack>
                            </Stack> :
                                <Stack gap={1.5} direction={'row'} alignItems={'center'}>
                                    <Typography fontSize={12} fontFamily={"Roboto"} >
                                        Watch
                                    </Typography>
                                    <Checkbox
                                        color={"primary"}
                                        sx={{
                                            padding: 0,
                                            margin: 0,

                                        }}
                                        checked={nodeData?.isFavourite != null && nodeData?.isFavourite == "1" ? true : false}
                                        onChange={(e) => {
                                            onCheck(nodeData, e.target.checked ? "1" : "0")
                                        }}
                                    />

                                </Stack>)
                        } */}

                        <Stack gap={1.5} direction={'row'} alignItems={'center'}>
                            <Typography fontSize={12} fontFamily={"Roboto"} >
                                Watch
                            </Typography>
                            <Checkbox
                                color={"primary"}
                                sx={{
                                    padding: 0,
                                    margin: 0,

                                }}
                                checked={nodeData?.isFavourite != null && nodeData?.isFavourite == "1" ? true : false}
                                onChange={(e) => {
                                    onCheck(nodeData, e.target.checked ? "1" : "0")
                                }}
                            />

                        </Stack>

                    </Stack>}
                </Stack>
                <Stack px={3} mb={1.9} pt={0} flex={1} sx={{ overflow: 'hidden', mt: '15px' }}>
                    {/* {activeTab === 'overview' && <LocationsMap nodeData={nodeData} sensorData={sensorData} selectedDataId={selectedDataId} getData={getData} />} */}
                    {activeTab === 'overview' && (nodeData != null && (sensorData != null && sensorData.product != null && <LocationsMap nodeData={nodeData} sensorData={sensorData} selectedDataId={selectedDataId} getData={getData} showMap={showMap} onMapClose={onMapClose} setShowMap={setShowMap} />))}
                    {/* {activeTab === 'overview' && (nodeData != null && (sensorData != null && sensorData.product != null && sensorData.product === "4" ? <SmartVibrationOverview nodeData={nodeData} sensorData={sensorData} nodeId={selectedDataId} /> : <LocationsMap nodeData={nodeData} sensorData={sensorData} selectedDataId={selectedDataId} getData={getData} showMap={showMap} onMapClose={onMapClose} setShowMap={setShowMap}/>))} */}
                    {activeTab === 'timeline' && <TimeLine nodeData={nodeData} sensorData={sensorData} selectedDataID={selectedDataId} />}
                    {activeTab === 'data' && <NodeData sensorData={sensorData} selectedDataID={selectedDataId} />}
                    {activeTab === 'charts' && <Charts nodeData={nodeData} sensorData={sensorData} nodeId={selectedDataId} onUpdateNodeData={onUpdateNodeData} />}
                    {activeTab === 'events' && <Events nodeData={nodeData} sensorData={sensorData} nodeId={selectedDataId} />}
                    {activeTab === 'ml' && <MachineLearning modelSetupOpen={modelSetupOpen} setModelSetupOpen={setModelSetupOpen}></MachineLearning>}
                    {/* {activeTab === 'details' && <Details nodeData={nodeData} sensorData={sensorData} />} */}
                    {/* {activeTab === 'secondaryData' && <SecondaryData selectedDataID={selectedDataId} />} */}
                </Stack>
            </Stack >
            <Backdrop style={{ zIndex: 1500 }} open={isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
};
