import { Box, ButtonBase, Stack, Typography, Backdrop, CircularProgress, Checkbox, FormControlLabel, Breadcrumbs, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { ArrowBackIos } from '@mui/icons-material';
import { Groups } from '../../modules/Asset/Groups.jsx';
import { tabs } from './data/tabs.js';
import { Tabs } from '../../ui/Tabs/index.js';
import { LocationsMap } from '../Personnel/LocationsMap/LocationsMap.jsx';
import { Events } from '../Personnel/Events/Events.jsx';
import { FormikProvider, useFormik } from 'formik';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import api from '../../lib/axios';
import { Movement } from './Trips/Movement.jsx';
import { Charts } from './Telemetry/Chart.jsx';

export const PersonnelDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const selectedDataId = location.state != null && location.state.id != null ? location.state.id : ""
    const [isLoading, setIsLoading] = useState(false);
    const [arrayGroup, setArrayGroup] = useState([]);
    const [personnelData, setPersonnelData] = useState({});
    const [sensorData, setSensorData] = useState({});
    const [activeTab, setActiveTab] = useState(location.state != null && location.state.activeTab != null ? location.state.activeTab : tabs[0].value);
    const formik = useFormik({
        initialValues: {
            fenceName: '',
            alerts: true,
            sendTo: '',
            device: '',
            minTemperature: '',
            maxTemperature: '',
            minSpeed: '',
            maxSpeed: '',
        },
        onSubmit: (values) => {
            console.log(values);
        },
        validateOnChange: false,
    });

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        getGroupData()
        if (selectedDataId != "") {
            setIsLoading(true)
            getData()
        }
    }, []);

    function getGroupData() {
        // setIsLoading(true)
        api.get('/apis/Groups/activePersonnel').then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                setArrayGroup(resData)
                // setIsLoading(false)
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getData = () => {
        api.get('/apis/Personnels/byId/' + selectedDataId).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                var sensorData = data.sensor
                setPersonnelData(data)
                setSensorData(sensorData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateData = (data) => {
        var sensorData = data.tracker
        setPersonnelData(data)
        setSensorData(sensorData)
    }

    const updatePersonnelData = (data) => {
        setPersonnelData(data)
    }

    const apiCalling = async (groups) => {
        setIsLoading(true)
        var data = {
            groups: groups,
            sensorNumberID: personnelData.sensorNumberID,
            sensorNumber: personnelData.sensorNumber
        }
        console.log("data", data);
        api.put('/apis/Personnels/' + selectedDataId, data).then((res) => {
            if (res.data.success) {
                var data = res.data.data
                getData()
            }
        }).catch(function (error) {
            console.log(error);
        });

    }
    function onCheck(personnelData, isFavourite) {
        setIsLoading(true)
        var data = {
            sensorNumberID: personnelData.sensorNumberID,
            sensorNumber: personnelData.sensorNumber,
            isFavourite: isFavourite
        }
        api.put('/apis/Personnels/' + personnelData._id, data).then((res) => {

            getData()
        }).catch(function (error) {
            console.log(error);
        });
    }
    return (
        <FormikProvider value={formik}>
            <Stack width={'100%'}>
                <Stack pt={3}>
                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} px={3}>
                        <Box
                        // onClick={() => navigate(-1)}
                        >
                            <Stack
                                display={'inline-flex'}
                                direction={'row'}
                                alignItems={'center'}
                                sx={{
                                    svg: {
                                        fill: '#A0A3A6',
                                    },
                                }}
                                gap={0.5}
                            >

                                <Grid
                                    container
                                    justifyContent="space-between"
                                    spacing={3}
                                >
                                    <Grid item>
                                        <Stack direction="row" alignItems="center" gap={0.5} >
                                            <ArrowBackIos
                                                onClick={() => {
                                                    navigate(-1);
                                                }}
                                                sx={{
                                                    fontSize: "small",
                                                }}
                                            />
                                            <Breadcrumbs
                                                aria-label="breadcrumb"
                                                separator={<Typography fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6">/</Typography>}
                                            >

                                                <Typography
                                                    fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6"
                                                >
                                                    Personnel
                                                </Typography>

                                                {personnelData != null && personnelData.category && <Typography
                                                    fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6"
                                                >
                                                    {personnelData != null && personnelData.category ? personnelData.category : "-"}
                                                </Typography>}
                                                {personnelData != null && personnelData.type && <Typography
                                                    fontSize={15}
                                                    fontWeight={400}
                                                    fontFamily={'Roboto'}
                                                    color="#A0A3A6"
                                                >
                                                    {personnelData != null && personnelData.type ? personnelData.type : "-"}
                                                </Typography>}
                                                <Typography fontSize={15} fontWeight={500} color={'#151D26'}>
                                                    {personnelData != null && personnelData.firstName && personnelData.lastName ? personnelData.firstName + " " + personnelData.lastName : "-"}
                                                </Typography>
                                            </Breadcrumbs>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Box>
                        {/* <SearchInput /> */}
                    </Stack>

                    <Stack direction={'row'} justifyContent={'space-between'} gap={4} alignItems={'center'} px={3} mt={'15px'}>
                        <Stack direction={'row'} gap={6} alignItems={'center'}>
                            <Typography fontSize={20} fontWeight={500}>
                                {personnelData != null && personnelData.firstName && personnelData.lastName ? personnelData.firstName + " " + personnelData.lastName : "-"}
                            </Typography>
                            <Box
                                sx={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    backgroundColor: sensorData != null && sensorData.state == "1" ? "green.main" : "red.main",
                                }}
                            />
                        </Stack>

                        <Stack direction={'row'}>
                            <ButtonBase
                                sx={{
                                    px: '40px',
                                    border: '1px solid #E2E2EA',
                                    bgcolor: '#F6F8FA',
                                    borderRadius: '5px',
                                    height: '30px',
                                    mr: '10px'
                                }}
                                onClick={() => {
                                    navigate(`/personnel-listing/manage`, { state: { id: selectedDataId } })
                                }}
                            >
                                <Typography fontSize={12} fontWeight={500} color={"#151D26"} fontFamily={'Roboto'}>Edit</Typography>
                            </ButtonBase>
                            <Groups label="Groups">
                                {arrayGroup.length > 0 && <FormControlLabel control={<Checkbox
                                    checked={personnelData?.groups != null ? arrayGroup.length == personnelData?.groups.length : false}
                                    onChange={(e) => {
                                        var groups = personnelData?.groups ? personnelData?.groups : []
                                        if (arrayGroup.length == groups.length) {
                                            groups = []
                                        } else {
                                            groups = arrayGroup.map(data => data._id)
                                        }
                                        apiCalling(groups)
                                    }} />}
                                    label='All Groups' />}
                                {arrayGroup.map((item, index) => (
                                    <FormControlLabel
                                        key={index}
                                        control={<Checkbox
                                            checked={personnelData?.groups != null ? personnelData?.groups.includes(item._id) : false}
                                            onChange={(e) => {
                                                var groups = personnelData?.groups ? personnelData?.groups : []
                                                if (groups.includes(item._id)) {
                                                    groups = groups.filter(obj => obj != item._id)
                                                } else {
                                                    groups.push(item._id)
                                                }
                                                apiCalling(groups)
                                            }}
                                        />}
                                        label={item.name} />
                                ))}
                            </Groups>
                        </Stack>
                    </Stack>

                    <Stack mt={'22px'}>
                        <Stack width={'100%'}>
                            <Tabs data={tabs} activeTab={activeTab} setActiveTab={handleChangeTab} activeColor={'#151D26'} />
                        </Stack>
                    </Stack>

                    {activeTab === 'overview' && <Stack direction={'row'} mt={'15px'} justifyContent={'space-between'} alignItems={'center'} width={'100%'} px={3}>
                        <Stack gap={1.5} direction={'row'} width={'100%'}>
                            {personnelData?.isFavourite != null && personnelData?.isFavourite == "1" && <Box
                                sx={{
                                    p: "2px",
                                    borderRadius: '20px',
                                    bgcolor: "#27B2704F",
                                    height: '30px',
                                    alignContent: 'center',
                                }}
                            >
                                <Typography
                                    height="100%"
                                    width="100%"
                                    px="22px"
                                    borderRadius='20px'
                                    backgroundColor="#27B270"
                                    color={'#FFFFFF'}
                                    fontFamily={'Roboto'}
                                    fontWeight={400}
                                    fontSize={12}
                                    alignContent="center"
                                >
                                    Watching
                                </Typography>
                            </Box>}
                            {personnelData.groupData != null && personnelData.groupData.map(data => {
                                return (
                                    <Box
                                        key={data._id}
                                        sx={{
                                            px: 2,
                                            border: '1px solid #E2E2EA',
                                            borderRadius: '20px',
                                            bgcolor: "#F7F7F7",
                                            height: '30px',
                                        }}
                                    >
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            gap={2}
                                            height="100%"
                                        >
                                            <Typography
                                                color={'#44444F'}
                                                fontFamily={'Roboto'}
                                                fontWeight={400}
                                                fontSize={12}
                                            >
                                                {data.name}
                                            </Typography>
                                            <Box
                                                sx={{
                                                    width: 8,
                                                    height: 8,
                                                    borderRadius: '50%',
                                                    backgroundColor: data.color,
                                                }}
                                            />
                                        </Stack>
                                    </Box>
                                )
                            })}

                        </Stack>
                        <Stack gap={1.5} direction={'row'} alignItems={'center'}>
                            <Typography fontSize={12} fontFamily={"Roboto"} >
                                Watch
                            </Typography>
                            <Checkbox
                                color={"primary"}
                                sx={{
                                    padding: 0,
                                    margin: 0,

                                }}
                                checked={personnelData?.isFavourite != null && personnelData?.isFavourite == "1" ? true : false}
                                onChange={(e) => {
                                    onCheck(personnelData, e.target.checked ? "1" : "0")
                                }}
                            />
                        </Stack>
                    </Stack>}
                </Stack>
                <Stack px={3} pb={3} pt={0} flex={1} sx={{ overflow: 'hidden', mt: '15px' }}>
                    {activeTab === 'overview' && <LocationsMap personnelData={personnelData} sensorData={sensorData} getData={getData} />}
                    {activeTab === 'movement' && <Movement personnelData={personnelData} />}
                    {activeTab === 'events' && <Events personnelData={personnelData} sensorData={sensorData} />}
                    {activeTab === 'sensorData' && <Charts personnelData={personnelData} sensorData={sensorData} updatePersonnelData={updatePersonnelData} />}

                </Stack>
            </Stack >
            <Backdrop style={{ zIndex: 1500 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </FormikProvider >
    );
};
