import { Tabs } from "../../../ui/Tabs"
import { DefaultInput } from "../../../ui/DefaultInput"
import { Backdrop, ButtonBase, Stack, Typography, CircularProgress } from "@mui/material"
import { FormikProvider, useFormik } from "formik"
import { DefaultToggle } from '../../../ui/DefaultToggle/DefaultToggle.jsx';
import { enqueueSnackbar } from "notistack";
import { DefaultDataGrid } from "../../Asset/LocationsMap/DefaultDataGrid";
import { geoFenceColumns } from "./data/arrayData";
import { ConfirmAlert } from "../../../customControls/confirmAlert";
import { Add } from "@mui/icons-material";
import { useEffect, useState } from "react";
import api from '../../../lib/axios.js';
import * as Yup from 'yup';
import { toolsTabs } from '../../../modules/Asset/data/toolsTabs.jsx';



export const Geofence = ({ nodeData, arrayData, setAddGeoFence, addGeoFence, setDrawData, drawData, setSelectedPoligon, selectedPoligon, setActiveTab, activeTab, getPoligon }) => {
    // const [activeTab, setActiveTab] = useState(toolsTabs[0].value);
    // const [arrayData, setArrayData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [addGeoFence, setAddGeoFence] = useState(false);
    // const [drawData, setDrawData] = useState(null);
    // const [selectedPoligon, setSelectedPoligon] = useState(null);
    const [deleteConfirmationShouldOpen, setDeleteConfirmationShouldOpen] = useState(false);
    const [selectedDataId, setSelectedDataId] = useState("");


    const formik = useFormik({
        initialValues: {
            submit: null,
            fenceName: '',
            isAlert: false,
            email: ''
        },
        validationSchema: Yup.object().shape({
            fenceName: Yup.string().required('Fence name is required'),
            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
        }),
        onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
                var dataValues = {
                    submit: null,
                    fenceName: values.fenceName,
                    isAlert: values.isAlert,
                    email: values.email,
                }
                formik.setValues(dataValues)
                if (drawData == null) {
                    enqueueSnackbar("Please draw poligon to add geofece", {
                        anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                        },
                        variant: 'error'
                    });
                } else {
                    setIsLoading(true)
                    var data = await save(values.fenceName, values.isAlert, values.email);
                    setIsLoading(false)
                    if (data !== undefined && data.data.success) {
                        setStatus({ success: true });
                        setSubmitting(false);
                        getPoligon()
                    } else {
                        setStatus({ success: false });
                        setSubmitting(false);
                        enqueueSnackbar(data.data.message, {
                            anchorOrigin: {
                                horizontal: 'right',
                                vertical: 'top'
                            },
                            variant: 'error'
                        });
                    }
                }

                setSubmitting(false);
            } catch (err) {
                console.log("inCATCH", err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
            }
        },
    })

    useEffect(() => {
        console.log("addGeoFence", addGeoFence);

    }, [addGeoFence]);

    const handleChangeTab = (tab) => {
        setActiveTab(tab);
    };

    async function save(name, isAlert, email) {
        let arrayData = []
        const data = drawData;
        if (data.features.length > 0) {
            if (selectedPoligon == null) {
                data.features.map(res => {
                    var feature = {
                        "email": email,
                        "notification": isAlert ? "1" : "0",
                        "name": name,
                        "region": {
                            "type": "Polygon",
                            "coordinates":
                                res.geometry.coordinates
                        },
                        "mode": activeTab,
                    }
                    arrayData.push(feature)
                })
                if (arrayData.length > 0) {
                    await addData(arrayData)
                }
            } else {
                var updatedData = data.features.find(obj => obj.id == selectedPoligon._id)
                if (updatedData) {
                    await updateData(name, updatedData, selectedPoligon, email, isAlert)
                }
            }
        }
    }

    async function addData(arrayData) {
        var data = {
            nodeID: nodeData._id,
            arrayData: arrayData
        }
        return await api.post('/apis/NodeGeoFencings/', data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                var dataValues = {
                    submit: null,
                    fenceName: "",
                    isAlert: false,
                    email: null
                }
                formik.setValues(dataValues)
                setDrawData(null)
                setAddGeoFence(false)

                enqueueSnackbar('Poligon added', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getPoligon()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    async function updateData(name, feature, selectedPoligon, email, isAlert) {
        var data = {
            "name": name,
            "email": email,
            "notification": isAlert ? "1" : "0",
            "region": {
                "type": "Polygon",
                "coordinates":
                    feature.geometry.coordinates
            },
        }
        return await api.put('/apis/NodeGeoFencings/' + selectedPoligon._id, data).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("Put", resData);
                var dataValues = {
                    submit: null,
                    fenceName: "",
                    isAlert: false,
                    email: null
                }
                formik.setValues(dataValues)
                setSelectedPoligon(null)
                setDrawData(null)
                setAddGeoFence(false)

                enqueueSnackbar('Poligon updated', {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                getPoligon()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onDeleteClick = (row) => {
        setSelectedDataId(row._id)
        setDeleteConfirmationShouldOpen(true)
    };

    const onEditClick = (row) => {
        setSelectedPoligon(row)
        var value = {
            fenceName: row.name,
            isAlert: row.notification == "1" ? true : false,
            email: row.email
        }
        formik.setValues(value)
        setAddGeoFence(true)
    };

    function deleteRecord() {
        api.delete('/apis/NodeGeoFencings/' + selectedDataId).then((res) => {
            if (res.data.success) {
                enqueueSnackbar("Poligon deleted", {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'success'
                });
                setSelectedDataId("")
                getPoligon()
            } else {
                enqueueSnackbar(res.data.message, {
                    anchorOrigin: {
                        horizontal: 'right',
                        vertical: 'top'
                    },
                    variant: 'error'
                });

            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const updateConfirmAlert = (value) => {
        if (value) {
            deleteRecord()
        } else {
            setSelectedDataId("")
        }
    }

    return (
        <Stack mt={-3}>
            {/* {!addGeoFence && <Stack mb={2}>
                <ButtonBase
                    sx={{
                        pl: 2,
                        pr: 0.5,
                        border: '1px solid #E2E2EA',
                        borderRadius: '5px',
                        height: '30px',
                        width: '160px'
                    }}
                    onClick={() => {
                        setAddGeoFence(true)
                        // hideShareLocation()
                    }}
                >

                    <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                        <Typography color={'#44444F'} fontFamily={'Roboto'} fontWeight={'Regular'} fontSize={14}>Add Geofence</Typography>
                        <Add
                            sx={{
                                color: 'secondary.main',
                                width: 24,
                                height: 24
                            }}
                        />
                    </Stack>
                </ButtonBase>
            </Stack>} */}
            {addGeoFence && <FormikProvider value={formik}>
                <Stack gap={1} component={'form'}
                    onSubmit={formik.handleSubmit}>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                            Fence Name
                        </Typography>
                        <DefaultInput
                            defaultValue={formik.values.fenceName}
                            placeholder={'Enter name...'}
                            name={'fenceName'}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.fenceName && formik.errors.fenceName)}
                            helperText={formik.touched.fenceName && formik.errors.fenceName} />
                    </Stack>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                            Select Tool
                        </Typography>
                        <Stack
                            flex={1}
                            sx={{
                                height: 40,
                                border: '1px solid #E2E2EA',
                                borderRadius: '6px',
                            }}
                        >
                            <Tabs data={toolsTabs} setActiveTab={handleChangeTab} activeTab={activeTab} inline small />
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                            Alerts
                        </Typography>
                        <Stack
                            direction={'row'}
                            gap={1}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            flex={1}
                            sx={{
                                height: 40,
                                border: '1px solid #E2E2EA',
                                borderRadius: '6px',
                                pl: 2,
                                pr: 1,
                            }}
                        >
                            <Typography fontSize={14}>Activate Alerts</Typography>
                            <DefaultToggle name={'isAlert'} />
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                        <Typography width={80} flexShrink={0} color={'#A0A3A6'} fontSize={12} fontFamily={'Roboto'} fontWeight={400} >
                            Send To
                        </Typography>
                        <DefaultInput
                            defaultValue={formik.values.email}
                            placeholder={'Enter email'}
                            name={'email'}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.email && formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email}
                        />
                    </Stack>
                    <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#EFEFEF',
                                borderRadius: '5px',
                            }}
                            onClick={() => {
                                setDrawData(null)
                                setAddGeoFence(false)
                                setSelectedPoligon(null)
                                var value = {
                                    fenceName: '',
                                    isAlert: false,
                                    email: null
                                }
                                formik.setValues(value)
                            }}
                        >
                            <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                Cancel
                            </Typography>
                        </ButtonBase>
                        <ButtonBase
                            sx={{
                                py: 1,
                                px: 3,
                                border: '1px solid #E2E2EA',
                                bgcolor: '#FCFCFE',
                                borderRadius: '5px',
                            }}
                            disabled={formik.isSubmitting}
                            type="submit"
                        >
                            <Typography fontSize={13} fontFamily={'Roboto'} fontWeight={700} color={'#151D26'}>
                                Save
                            </Typography>
                        </ButtonBase>
                    </Stack>
                </Stack>
            </FormikProvider>}
            {!addGeoFence && <DefaultDataGrid rows={arrayData} columns={geoFenceColumns(onDeleteClick, onEditClick, setAddGeoFence)} disablePagination onRowClick={(e) => {
                if (e.row.path) {
                    window.open(e.row.path, '_blank');
                }
            }} sx={{
                '.MuiDataGrid-columnHeader': {
                    '&:last-of-type': {
                        overflow: 'hidden',
                        pr: 0,
                    },
                    '&:last-of-type .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '&:first-of-type': {
                        pl: 0,
                    },
                    bgcolor: "#F6F8FA",
                    borderBottom: '1px dashed #8F98AB45',  // Add this for dashed bottom border
                },
                '.MuiDataGrid-cell': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    bgcolor:'#F6F8FA',
                    border: 'none',
                    '&:last-of-type': {
                        pr: 0,
                    },
                    '&:focus': {
                        outline: 'none',
                    },
                    '&:first-of-type': {
                        pl: 0,
                    },

                    borderBottom: '1px dashed #8F98AB45',  // Dashed border for cells
                    maxWidth: '200px',
                },
                '.MuiDataGrid-row:nth-of-type(even)': {
                    bgcolor: '#F6F8FA',

                    '.MuiAvatar-root': {
                        backgroundColor: 'secondary.main',
                        color: 'common.white',
                    },
                },
            }} ></DefaultDataGrid>}
            {
                deleteConfirmationShouldOpen && (<ConfirmAlert title={"Confirmation"} body={"Are you sure you want to delete?"}
                    updateConfirmAlert={(e, value) => {
                        setDeleteConfirmationShouldOpen(false)
                        updateConfirmAlert(value)
                    }} />)
            }
            <Backdrop style={{ zIndex: 4 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack>
    )
}