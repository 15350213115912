import { Checkbox, Stack, Typography } from '@mui/material';


export const columns = (arrayData, onCheck, handleHeaderClick, formik) => [
    {
        field: 'sensorNumber',
        headerName: 'ID (Serial)',
        width: 150,
        renderCell: (value) => (
            <Typography fontSize={12}>
                {value.row.sensorNumber ? value.row.sensorNumber : value.row.serialNumber ? value.row.serialNumber : '-'}
            </Typography>
        ),
    },
    {
        field: 'nodeName',
        headerName: 'Name',
        flex: 1,
        renderCell: (params) => (
            <Typography fontSize={12}>
                {params.row && params.row.firstName && params.row.lastName ? params.row.firstName + " " + params.row.lastName : '-'}
            </Typography>
        ),
    },
    {
        field: 'selected',
        headerName: 'Rule Action',
        width: 150,

        renderHeader: (params) => (
            <Stack direction="row" alignItems="center">
                <Typography style={{ cursor: 'pointer' }} fontFamily={'Roboto'} fontWeight={'Bold'} color={'#615D5D'}>
                    Rule Action
                </Typography>
                <Checkbox
                    color="primary"
                    checked={arrayData.length > 0 ? formik.values.arraySelected.length == arrayData.length : false}
                    onChange={handleHeaderClick}
                />
            </Stack>
        ),
        renderCell: (params) => (
            <Stack alignItems={'flex-start'} width={'100%'} pr={5}>
                <Checkbox color="primary"
                    onChange={(value) => {
                        onCheck(params.row)
                    }}
                    checked={formik.values.arraySelected != null ? formik.values.arraySelected.includes(params.row._id) : false}
                />
            </Stack>
        ),
        sortable: false,
        disableColumnMenu: true,
    },
];
export const trackersRows = [
    {
        id: 1,
        deviceSerialNumber: '112211',
        assignedAsset: 'None',
        status: {
            text: 'Available',
            borderColor: 'green.main',
        },
        type: 'YabbyEdge4-G',
        role: {
            text: 'None',
            textColor: '#0A00FF',
            borderColor: '#0A00FF',
        },
        billing: 'None',
        timestamp: '10 minutes ago',
    },
    {
        id: 2,
        deviceSerialNumber: '113344',
        assignedAsset: 'None',
        status: {
            text: 'Available',
            borderColor: 'green.main',
        },
        type: 'YabbyEdge4-G',
        role: {
            text: 'None',
            textColor: '#0A00FF',
            borderColor: '#0A00FF',
        },
        billing: 'None',
        timestamp: '10 minutes ago',
    },
];
