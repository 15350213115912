import { Box, Stack, Typography, Backdrop, CircularProgress, ButtonBase, IconButton, MenuItem } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Tabs } from '../../../ui/Tabs/index.js';
import { Datepicker } from '../../../ui/Datepicker/index.js';
import { internalEventTabs } from '../../../modules/Asset/data/internalSensorsTabs.js';
import { DefaultDataGrid } from '../../../ui/DefaultDataGrid/index.js';
import { eventsColumns } from '../data/events.jsx';
import dayjs from 'dayjs';
import api from '../../../lib/axios.js';
import { Filter } from '../../../ui/Filter/Filter.jsx';
import SettingsIcon from '@mui/icons-material/Settings';
import { columns } from '../../../modules/RuleListing/data/rule.jsx';
import { arrayCondition, arrayMeasurment } from '../../../modules/RuleNew/data/arrayData.jsx';
import { arrayDeviceProduct, arrayDeviceType } from '../../../modules/RuleNew/data/arrayData.jsx';
import moment from 'moment';
import Papa from 'papaparse';
import { useNavigate } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';
import excelFile from '../../../assets/images/excelFile.svg';

export const Events = ({ nodeData, sensorData, nodeId }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(internalEventTabs[0].value);
    const [arrayNotification, setArrayNotification] = useState([])
    const [arrayFilter, setArrayFilter] = useState([])
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [isForRuleListing, setIsForRuleListing] = useState(false);
    const [selectedAllEvent, setSelectedAllEvents] = useState(-1)
    const [arrayRule, setArrayRule] = useState([])
    const [arrayEvents, setArrayEvents] = useState([])
    const [filterArrayData, setFilteredArrayData] = useState([])
    const [arrayEventsCounts, setArrayEventsCounts] = useState([]);


    // useEffect(() => {
    //     if (sensorData != null) {
    //         getEventList()
    //     }
    // }, [sensorData, startDate, endDate]);

    useEffect(() => {
        if (isForRuleListing) {
            setIsLoading(true)
            getRuleListByNode()
        } else {
            if (sensorData != null) {
                getEventList()
                // setIsLoading(false)
            }
        }
    }, [isForRuleListing, sensorData, startDate, endDate]);

    const handleStartDateChange = (date) => {
        setStartDate(date)
        console.log(date)
    };

    const handleEndDateChange = (date) => {
        setEndDate(date)
        console.log(date)
    };

    function calculateDuration(startTime, endTime) {
        const start = new Date(startTime);
        const end = new Date(endTime);

        const durationMs = end - start;

        const days = Math.floor(durationMs / (1000 * 60 * 60 * 24));
        const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
        const seconds = Math.floor((durationMs / 1000) % 60);

        let durationString = "";
        if (days > 0) {
            durationString += days + (days === 1 ? " Day " : " Days ");
        }
        if (hours > 0) {
            durationString += hours + (hours === 1 ? " hr " : " hrs ");
        }
        if (minutes > 0) {
            durationString += minutes + (minutes === 1 ? " min " : " mins ");
        }
        if (durationString.trim() === "") {
            durationString = "Just Now";
        }
        return durationString.trim();
    }

    const getEventList = () => {
        setIsLoading(true)
        let data = {
            startDate: startDate.format("MM/DD/YYYY"),
            endDate: endDate.format("MM/DD/YYYY"),
            nodeID: nodeId,
        }
        console.log("data", data);

        api.post('/apis/RuleEvents/byNode', data).then((res) => {
            if (res.data.success) {
                const resData = res.data.data.arrayData
                const arrayEvent = res.data.data.eventsWithCounts
                console.log("resData", resData)
                var filterArray = applyFilters(resData, selectedAllEvent)
                console.log(filterArray)
                setArrayEvents(resData)
                setArrayEventsCounts(arrayEvent)
                setFilteredArrayData(filterArray)
            }
            // getRuleListByNode()
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getRuleListByNode = () => {
        // setIsLoading(true)
        api.get('/apis/Rules/byNode/' + nodeId).then((res) => {
            if (res.data.success) {
                var resData = res.data.data
                console.log("resData", resData)
                setArrayRule(resData)
            }
            setIsLoading(false)
        }).catch(function (error) {
            console.log(error);
        });
    }

    const applyFilters = (arrayDataAll, selectedEvents) => {
        var finalArray = arrayDataAll
        if (selectedEvents !== null && selectedEvents != -1) {
            if (arrayDataAll.length > 0) {
                var filteredArrayEvents = arrayDataAll
                    .filter((dataObj) => {
                        let matches = true;
                        let containsQuery = false;

                        if (dataObj.ruleID === selectedEvents) {
                            containsQuery = true;
                        }

                        if (!containsQuery) {
                            matches = false;
                        }
                        return matches;
                    });
            } else {
                return arrayDataAll

            }
            finalArray = [...filteredArrayEvents]
        }

        if (selectedEvents === -1) {
            return arrayDataAll
        }
        return finalArray
    }

    const handleExportEventsData = () => {
        if (filterArrayData.length > 0) {
            const updatedArrayData = filterArrayData.map((res) => {
                const timeStamp = moment(res.updateAt).format("MM/DD/YY hh:mma")
                const measurment = arrayMeasurment.find((measurement) => measurement.id == res.sensorMesurement)?.value
                const condition = arrayCondition.find((condition) => condition.id == res.condition)?.value
                return { "Event ID": res.eventID, Rule: res.ruleName, Measurement: measurment, Condition: condition, "Data value": res.value, Timestamp: timeStamp };
            });
            const csvData = Papa.unparse(updatedArrayData);

            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `Events.csv`;
            link.click();

            URL.revokeObjectURL(url);
        } else {
            enqueueSnackbar("There is no data to export", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }

    }

    const handleExportRulesData = () => {
        if (arrayRule.length > 0) {
            const updatedArrayData = arrayRule.map((res) => {
                const timeStamp = moment(res.updatedAt).format("MM/DD/YY hh:mma")
                const status = res.status == "1" ? "Active" : "Disabled"
                const device = arrayDeviceType.find((type) => type.id == res.type)?.value
                const product = arrayDeviceProduct.find((product) => product.id == res.product)?.value
                return { "Rule Name": res.ruleName, Status: status, Product: product, Device: device, "Last Modified": timeStamp };
            });
            const csvData = Papa.unparse(updatedArrayData);

            const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });

            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = `Rules.csv`;
            link.click();

            URL.revokeObjectURL(url);
        } else {
            enqueueSnackbar("There is no data to export", {
                anchorOrigin: {
                    horizontal: 'right',
                    vertical: 'top'
                },
                variant: 'error'
            });
        }

    }

    return (
        <>
            <Stack
                sx={{
                    overflowY: 'auto',
                    flex: 1,
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        width: '4px',
                        backgroundColor: '#bdbdbd',
                    },
                }}
                maxWidth={'100%'}
            >
                <Stack mb={2} direction={'row'} gap={1} alignItems={'center'}>
                    {isForRuleListing == false && < Box maxWidth={200} width={'100%'} >
                        <Filter
                            onChange={(event) => {
                                var value = event.target.value
                                setSelectedAllEvents(value)
                                var filterArray = applyFilters(arrayEvents, value, "")
                                setFilteredArrayData(filterArray)
                            }}
                            defaultValue={selectedAllEvent}
                            placeholder="All Event"
                        >
                            {arrayRule.map((item, index) => (
                                <MenuItem key={index} value={item._id}> {item.ruleName}</MenuItem>
                            ))}
                        </Filter>
                    </Box>}
                    <Stack direction={'row'} gap={1} >
                        <Datepicker initialDate={startDate} handleDateChange={handleStartDateChange} />
                        <Datepicker initialDate={endDate} handleDateChange={handleEndDateChange} />
                    </Stack>
                    <ButtonBase
                        sx={{
                            pt: 1,
                            pb: 1,
                            px: 5,
                            border: '1px solid #E2E2EA',
                            bgcolor: isForRuleListing == true ? "#1E75FF" : "#FCFCFE",
                            borderRadius: '5px',
                            height: 30,
                        }}
                        onClick={() => {
                            if (isForRuleListing) {
                                setIsForRuleListing(false)
                            } else {
                                setIsForRuleListing(true)
                            }
                        }}
                    >
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'}>
                            <IconButton
                                sx={{
                                    height: 10,
                                    width: 10,
                                    pr: 2
                                }}
                            >
                                <SettingsIcon sx={{ color: isForRuleListing == true ? "#FFFFFF" : "#151D26", }} />
                            </IconButton >
                            <Typography fontSize={12} fontFamily={"Roboto"} fontWeight={'Medium'} color={isForRuleListing == true ? "#FAFAFB" : "#151D26"}>
                                {isForRuleListing == true ? "Events" : "Rules"}
                            </Typography>
                        </Stack>
                    </ButtonBase>
                    <ButtonBase
                        sx={{
                            height: 30,
                            width: 30,
                            px: 2,
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            border: '1px solid #E2E2EA',
                            bgcolor: '#F6F8FA',
                            borderRadius: '6px',
                        }}
                        onClick={() => {
                            if (!isForRuleListing) {
                                handleExportEventsData()
                            } else {
                                handleExportRulesData()
                            }
                        }}
                    >
                        <Box component={'img'} src={excelFile} sx={{ width: "18px", height: "22px" }} />
                    </ButtonBase>
                    <Stack direction={"row"} gap={'10px'}>
                        {arrayEventsCounts.map((data, index) => (
                            <Box
                                key={index}
                                sx={{
                                    border: '1px solid #E2E2EA',
                                    borderRadius: '20px',
                                    bgcolor: '#F7F7F7',
                                    height: '30px',
                                    pr: "34px",
                                    pl: '15px',
                                    alignContent: 'center'
                                }}
                            >
                                <Stack direction={'row'} gap={"3px"}>
                                    <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={400} color={'#44444F'}>{index == 0 ? data.eventType : arrayMeasurment.find(obj => obj.id == data.eventType)?.value}</Typography>
                                    <Typography fontSize={12} fontFamily={'Roboto'} fontWeight={700} color={'#44444F'}>{index == 0 ? data.count : data.count}</Typography>
                                </Stack>
                            </Box>
                        ))}
                    </Stack>
                    {/* <Tabs data={internalEventTabs} activeTab={activeTab} setActiveTab={handleChangeTab} inline /> */}
                </Stack>
                <Stack
                    flex={1}
                    direction={'row'}
                    sx={{
                        border: '1px solid #E2E2EA',
                        borderRadius: '8px 0 0 8px',
                        borderRight: 'none',
                        bgcolor: '#FFFFFF'
                    }}

                    width={'100%'}
                    overflow={'hidden'}
                >
                    {isForRuleListing == false && <DefaultDataGrid rows={filterArrayData} columns={eventsColumns} />}
                    {isForRuleListing == true && <DefaultDataGrid rows={arrayRule} columns={columns(calculateDuration, arrayDeviceType)} onRowClick={(e) => navigate(`/rule-listing/manage`, { state: { id: e.row._id } })} />}
                </Stack>

            </Stack >
            <Backdrop style={{ zIndex: 1 }} open={isLoading} >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );

};
